import httpClient, {
  ApiResponseType,
  HttpRequestConfig,
  parsePreloadValue,
} from 'packages/http_client';
import {
  GetArticlesParams, GetArticleListResponse, GetArticlesApiResponse, ARTICLES_API,
  GetArticleByIdApiResponse,
  GetArticleByIdResponse,
} from 'packages/articles_repository';
import { convertGetArticleListResponse, convertGetArticleResponse, requestsQuery } from './utils';

const getArticlesApi = (query?: GetArticlesParams) => {
  const url = ARTICLES_API;
  const params = requestsQuery(query);
  const config: HttpRequestConfig = { params }
  return httpClient.get<ApiResponseType<GetArticlesApiResponse>>(url, config);
}

export const getArticles = async (params?: GetArticlesParams): Promise<GetArticleListResponse> => {
  const response = await getArticlesApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertGetArticleListResponse(data?.result!) };
}

const getArticlesByIdApi = (id: number, preload?: boolean) => {
  const url = `${ARTICLES_API}/${id}`;
  const config: HttpRequestConfig = {
    params: {
      $preload: parsePreloadValue(preload),
    },
  }
  return httpClient.get<ApiResponseType<GetArticleByIdApiResponse>>(url, config)
}

export const getArticleById = async (id: number, preload?: boolean): Promise<GetArticleByIdResponse> => {
  const response = await getArticlesByIdApi(id, preload);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertGetArticleResponse(data?.result!) };
}
