import React, { useEffect } from 'react';
import { Button, Form } from 'antd';

import { ADGMLicense, addMissingDetailsHook } from 'components/AddTechnicalHolderDetails';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { ADGMLicenseUploadFormProps } from 'types/technicalLicenses';
import { identity } from 'utils';

import './ADGMLicenseUploadForm.scss';

const TLHADGMLicenseUploadForm = (props: ADGMLicenseUploadFormProps) => {
  const {
    adgm,
    technicalLicenseId,
    actions: { createTechnicalLicenseADGMLicense, updateADGMLicenseByTechnicalLicenseId },
  } = props;
  const {
    state,
    setHasLicense,
    setFileName,
    setADGMAll,
    setADGMField,
  } = addMissingDetailsHook();
  const {
    adgmLicense,
    hasLicense,
    fileName,
  } = state;

  useEffect(() => {
    if (identity.isObjWithChildren(adgm) && identity.isTruthyString(adgm?.aDGMLicenseUrl)) {
      setFileName(adgm.aDGMLicenseUrl);
      setADGMAll(adgm);
      setHasLicense(true);
    }
  }, [adgm]);

  const handleSubmitButton = () => {
    if (hasLicense) {
      if (identity.isTruthyNumber(adgm?.id)) {
        updateADGMLicenseByTechnicalLicenseId({ id: technicalLicenseId, adgmLicense });
      } else {
        createTechnicalLicenseADGMLicense({ ...adgmLicense, technicalLicenseId });
      }
    }
  };

  const onSetHasLicense = (value: DataPayload<boolean>) => {
    setHasLicense(value.data);
  }

  return (
    <div className="adgm-license-upload-form__container">
      <Form scrollToFirstError>
        <ADGMLicense
          fileName={fileName as string}
          hasLicense={hasLicense}
          adgmLicense={adgmLicense}
          setADGMField={setADGMField}
          isAddMissingDetails
          setHasLicense={onSetHasLicense}
          setADGMAll={setADGMAll}
        />
        <div className="submit-button">
          <Button type="primary" htmlType="submit" disabled={!hasLicense} className="primary-button" onClick={handleSubmitButton}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default TLHADGMLicenseUploadForm;
