import produce from 'immer';

import {
  TechnicalLicenseMemberActionType,
  SetEventFilterPayload,
  SetEventsPayload,
  SetTechnicalLicenseMemberByIdPayload,
} from 'types/reduxTypes/ActionTypes/TechnicalLicenseMemberActionTypes';
import { TechnicalLicenseMembersStateType } from 'types/reduxTypes/StateTypes';
import { technicalLicenseMembersDefaultState } from '../../utils/defaultStates/MemberDefaultState';
import { types } from '../../actions/technicalLicenseMemberDetails';

const defaultState: TechnicalLicenseMembersStateType = technicalLicenseMembersDefaultState;

const handleSetTechnicalLicenseMemberById = (
  state: TechnicalLicenseMembersStateType,
  payload: SetTechnicalLicenseMemberByIdPayload,
) => {
  state.selectedMember.data = payload.member;
  return state;
};

const handleSetPastEvents = (state: TechnicalLicenseMembersStateType, payload: SetEventsPayload) => {
  state.selectedMember.pastEvents = payload.events;
  return state;
};

const handleSetUpcomingEvents = (state: TechnicalLicenseMembersStateType, payload: SetEventsPayload) => {
  state.selectedMember.upcomingEvents = payload.events;
  return state;
};

const handleSetEventFilter = (state: TechnicalLicenseMembersStateType, payload: SetEventFilterPayload) => {
  state.selectedMember.eventFilter = payload.eventFilter;
  return state;
};

export default produce((
  // eslint-disable-next-line default-param-last
  state: TechnicalLicenseMembersStateType = defaultState,
  action: TechnicalLicenseMemberActionType,
) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_EVENT_FILTER_TYPE: {
      return handleSetEventFilter(state, <SetEventFilterPayload> payload);
    }
    case types.SET_TECHNICAL_LICENSE_MEMBER_BY_ID: {
      return handleSetTechnicalLicenseMemberById(state, <SetTechnicalLicenseMemberByIdPayload> payload);
    }
    case types.SET_PAST_EVENTS: {
      return handleSetPastEvents(state, <SetEventsPayload> payload);
    }
    case types.SET_UPCOMING_EVENTS: {
      return handleSetUpcomingEvents(state, <SetEventsPayload> payload);
    }
    case types.RESET_TECHNICAL_LICENSE_MEMBER_DETAILS: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
