import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { EditTechnicalLicenseMemberType } from 'types/people';
import { SetTechnicalLicenseFieldType } from 'types/reduxTypes/ActionTypes/PeopleTypes';

export const types = {
  SET_TLH_FIELD: 'SET_TLH_FIELD',
  SET_TLH_ALL: 'SET_TLH_ALL',
  SET_TLH_EMIRATES_ID_PEOPLE: 'SET_TLH_EMIRATES_ID_PEOPLE',
  SHOW_TLH_ERROR: 'SHOW_TLH_ERROR',
  SET_TLH_VISA: 'SET_TLH_VISA',
  SET_TLH_PASSPORT: 'SET_TLH_PASSPORT',
}

export const setAll = (payload: DataPayload<EditTechnicalLicenseMemberType>) => ({
  type: types.SET_TLH_ALL,
  payload,
});

export const setShowError = (payload: SetTechnicalLicenseFieldType) => ({
  type: types.SHOW_TLH_ERROR,
  payload,
});

export const setField = (payload: SetTechnicalLicenseFieldType) => ({
  type: types.SET_TLH_FIELD,
  payload,
});

export const setEmiratesId = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_TLH_EMIRATES_ID_PEOPLE,
  payload,
});

export const setVisa = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_TLH_VISA,
  payload,
});

export const setPassport = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_TLH_PASSPORT,
  payload,
});
