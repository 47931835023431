import produce from 'immer';

import {
  ResetFormPayload, ServicesSpCountPayload,
  SetDestroyDrawerOnClosePayload,
  SetDrawerBodyPayload,
  SetIsDrawerFilterTableOpenPayload,
  SetIsDrawerOpenPayload,
  SetIsFilterShownPayload,
  SetTableLayoutPayload,
  WorkspaceActionType,
} from 'types/reduxTypes/ActionTypes/WorkspaceTypes'
import { SearchType } from 'packages/search_repository/types';
import { WorkspaceStateType } from 'types/reduxTypes/StateTypes';
import { NoticeModalConfig } from 'types/componentTypes';
import { CountryType } from 'packages/country_repository';
import { DataPayload, IsLoadingPayload, IsOpenPayload } from 'types/reduxTypes/ActionTypes';
import { AngelInvestorType } from 'types/angelInvestors';
import { StartupType } from 'types/startups';
import { ServiceProviderType } from 'packages/service_repository';
import { PartnerType } from 'packages/partner_repository';
import { types } from '../../actions/workspace';
import { defaultWorkspaceState } from '../../utils';

const defaultState: WorkspaceStateType = defaultWorkspaceState;

const handleSetTableLayout = (state: WorkspaceStateType, payload: SetTableLayoutPayload) => {
  state.isRowTableLayout = payload.isRowTableLayout;
  return state;
}

const handleNavigationSearch = (state: WorkspaceStateType, payload: DataPayload<SearchType[]>) => {
  state.searchResults = payload.data;
  return state;
}

const handleSetIsDrawerLoading = (state: WorkspaceStateType, payload: IsLoadingPayload) => {
  state.drawer.isDrawerLoading = payload.isLoading;
  return state;
}

const handleSetIsDrawerOpen = (state: WorkspaceStateType, payload: SetIsDrawerOpenPayload) => {
  state.drawer.isDrawerOpen = payload.isDrawerOpen;
  return state;
}

const handleSetFilterShown = (state: WorkspaceStateType, payload: SetIsFilterShownPayload) => {
  state.isFilterShown = payload.isFilterShown;
  return state;
}

const handleSetDestroyDrawerOnClose = (state: WorkspaceStateType, payload: SetDestroyDrawerOnClosePayload) => {
  state.drawer.destroyDrawerOnClose = payload.destroyDrawerOnClose;
  return state;
};

const handleSetIsDrawerFilterTableOpen = (state: WorkspaceStateType, payload: SetIsDrawerFilterTableOpenPayload) => {
  state.drawer.isDrawerFilterTableOpen = payload.isDrawerFilterTableOpen;
  return state;
};

const handleSetDrawerBody = (state: WorkspaceStateType, payload: SetDrawerBodyPayload) => {
  state.drawer.drawerBody = payload.drawerBody;
  return state;
}

const handleResetForm = (state: WorkspaceStateType, payload: ResetFormPayload) => {
  state.resetForm = payload.resetForm;
  return state;
}

const handleSetNationalities = (state: WorkspaceStateType, payload: DataPayload<CountryType[]>) => {
  state.nationalities = payload.data;
  return state;
}

const handleSetAngelInvestors = (state: WorkspaceStateType, payload: DataPayload<AngelInvestorType[]>) => {
  state.angelInvestors = payload.data;
  return state;
}

const handleSetStartups = (state: WorkspaceStateType, payload: DataPayload<StartupType[]>) => {
  state.startups = payload.data;
  return state;
}

const handleSetIsNoticeModalOpen = (state: WorkspaceStateType, payload: IsOpenPayload) => {
  state.noticeModal.isOpen = payload.isOpen;
  return state;
}

const handleSetNoticeModalTemplate = (state: WorkspaceStateType, payload: DataPayload<NoticeModalConfig>) => {
  state.noticeModal.template = payload.data;
  return state;
}

const handleSetServiceProviders = (state: WorkspaceStateType, payload: DataPayload<ServiceProviderType[]>) => {
  state.serviceProviders = payload.data;
  return state;
}

const handleSetPartners = (state: WorkspaceStateType, payload: DataPayload<PartnerType[]>) => {
  state.partners = payload.data;
  return state;
}

const handleSetServiceSpCount = (state: WorkspaceStateType, payload: ServicesSpCountPayload) => {
  state.servicesCount = payload.servicesCount;
  state.serviceProvidersCount = payload.serviceProvidersCount;
  return state;
}

// NOTE: produce recipe accepts state as first parameter, and we need to set its default value. Since eslint requires that default assignments are last, we need to disable this rule only in this case.
// eslint-disable-next-line default-param-last
export default produce((state: WorkspaceStateType = defaultState, action: WorkspaceActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_TABLE_LAYOUT: {
      return handleSetTableLayout(state, <SetTableLayoutPayload>payload);
    }
    case types.SET_SEARCH_RESULT: {
      return handleNavigationSearch(state, <DataPayload<SearchType[]>>payload);
    }
    case types.SET_IS_DRAWER_LOADING: {
      return handleSetIsDrawerLoading(state, <IsLoadingPayload> payload);
    }
    case types.SET_IS_DRAWER_OPEN: {
      return handleSetIsDrawerOpen(state, <SetIsDrawerOpenPayload> payload);
    }
    case types.SET_FILTER_SHOWN: {
      return handleSetFilterShown(state, <SetIsFilterShownPayload> payload);
    }
    case types.SET_DESTROY_DRAWER_ON_CLOSE: {
      return handleSetDestroyDrawerOnClose(state, <SetDestroyDrawerOnClosePayload> payload);
    }
    case types.SET_IS_DRAWER_FILTER_TABLE_OPEN: {
      return handleSetIsDrawerFilterTableOpen(state, <SetIsDrawerFilterTableOpenPayload> payload);
    }
    case types.SET_DRAWER_BODY: {
      return handleSetDrawerBody(state, <SetDrawerBodyPayload> payload);
    }
    case types.RESET_FORM: {
      return handleResetForm(state, <ResetFormPayload>payload);
    }
    case types.SET_NATIONALITIES: {
      return handleSetNationalities(state, <DataPayload<CountryType[]>>payload);
    }
    case types.SET_ALL_STARTUPS: {
      return handleSetStartups(state, <DataPayload<StartupType[]>>payload);
    }
    case types.SET_ALL_ANGEL_INVESTORS: {
      return handleSetAngelInvestors(state, <DataPayload<AngelInvestorType[]>>payload);
    }
    case types.SET_IS_NOTICE_MODAL_OPEN: {
      return handleSetIsNoticeModalOpen(state, <IsOpenPayload>payload);
    }
    case types.SET_NOTICE_MODAL_TEMPLATE: {
      return handleSetNoticeModalTemplate(state, <DataPayload<NoticeModalConfig>>payload);
    }
    case types.SET_WORKSPACE_PARTNERS: {
      return handleSetPartners(state, <DataPayload<PartnerType[]>>payload);
    }
    case types.SET_WORKSPACE_SERVICE_PROVIDERS: {
      return handleSetServiceProviders(state, <DataPayload<ServiceProviderType[]>>payload);
    }
    case types.SET_WORKSPACE_SERVICES_SP_COUNT: {
      return handleSetServiceSpCount(state, <ServicesSpCountPayload>payload);
    }
    case types.RESET_WORKSPACE: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
