import React, { useEffect } from 'react';
import { TitleWithActions } from 'components/components';
import { ArticleRow } from 'components/RequestsOverview/components';
import routes from 'routes';
import { identity } from 'utils';
import { ArticlesType } from 'types/articles';
import { ArticlesProps } from 'packages/articles_repository/types/articlesOverview';

import './Articles.scss';

const Articles = (props: ArticlesProps) => {
  const { articles, actions } = props

  const {
    getArticles,
  } = actions;

  useEffect(() => {
    getArticles({ data: { limit: 10, offset: 0 } })
  }, [])

  return (
    <div className="articles">
      <TitleWithActions
        title="Articles"
        size="xs"
        link={routes.articlesUrl}
      />
      <div className="articles-overview">
        <div className="articles-container">
          {identity.isFullArray(articles)
            ? articles.map((article: ArticlesType, index: number) => <ArticleRow request={article} key={`request-${article.id || index}`} />).slice(0, 10)
            : 'No articles'}
        </div>
      </div>
    </div>
  );
}

export default Articles;
