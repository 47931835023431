import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { toggleNotifications } from 'redux/actions/notification';
import { selectIsOpen } from 'redux/selectors/notifications';
import { setFilterShown } from 'redux/actions/workspace';
import { selectIsFilterShown } from 'redux/selectors/workspace';
import { selectUserType } from '../../../../redux/selectors/auth';
import { StateType } from '../../../../types';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  isOpen: selectIsOpen(state),
  isFilterShown: selectIsFilterShown(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    toggleNotifications,
    setFilterShown,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
