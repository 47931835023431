import React, { useEffect } from 'react';
import { Button, Form, Spin } from 'antd';
import { useHistory } from 'react-router';
import { RcFile } from 'antd/lib/upload';

import {
  identity,
  // isTechnicalHolderDataMissing,
  isTechnicalLicense,
  requiredValidation,
} from 'utils';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { AddMissingDetailsProps } from 'types/addDetails';
import { EditProfileImage, Input } from 'components/components';
import routes from 'routes';
import { ADGMLicense, AcceptanceLetter } from './components';
import Title from '../components/TitleWithActions/components/Title';
import { addMissingDetailsHook, disableAddMissingDetailsSubmitButton, getFileName } from './utils';

import './AddTechnicalHolderDetails.scss';

const AddTechnicalHolderDetails = (props: AddMissingDetailsProps) => {
  const {
    details,
    sectorOptions,
    stageOptions,
    isAuthenticated,
    startupId,
    adgmLicenseByStartupId,
    isLoading,
    userType,
  } = props;

  const {
    getStartupSectors,
    getStartupStages,
    updateStartupById,
    getADGMLicenseByStartupId,
    updateADGMLicenseByStartupId,
    createStartupADGMLicense,
  } = props.actions;

  const {
    // setAll,
    state,
    setField,
    setHasLicense,
    setFileName,
    setADGMAll,
    setADGMField,
  } = addMissingDetailsHook();
  const {
    startup,
    adgmLicense,
    hasLicense,
    fileName,
  } = state;
  const history = useHistory();

  useEffect(() => {
    if (identity.isFalsy(isAuthenticated)) {
      history.push(routes.dashboardUrl);
    }
  }, []);

  const onSetHasLicense = (value: DataPayload<boolean>) => {
    setHasLicense(value.data);
    if (value.data) {
      setADGMField({ field: 'aDGMLicenseNo', data: adgmLicenseByStartupId?.aDGMLicenseNo });
    }
  }

  useEffect(() => {
    if (isTechnicalLicense(userType) && identity.isObjWithChildren(details)) {
      if (details.id === startupId) {
        if (startupId !== adgmLicenseByStartupId.startupID) {
          getADGMLicenseByStartupId({ id: startupId });
        }
        if (identity.isEmptyArray(sectorOptions)) {
          getStartupSectors();
        }
        if (identity.isEmptyArray(stageOptions)) {
          getStartupStages();
        }
      }
    }
  }, [startupId]);

  //   useEffect(() => {
  //     if (identity.isObjWithChildren(details)) {
  //       if (isTechnicalHolderDataMissing(details)) {
  //         setAll({ data: details });
  //         const hasAdgm = !identity.isZero(details.currentADGMLicenseID !== 0);
  //         setHasLicense(hasAdgm);
  //       } else {
  //         history.push(routes.dashboardUrl);
  //       }
  //     }
  //   }, [details]);

  useEffect(() => {
    if (hasLicense && !identity.isZero(adgmLicenseByStartupId.id)) {
      setADGMAll({ data: adgmLicenseByStartupId });
    }
  }, [hasLicense, adgmLicenseByStartupId]);

  useEffect(() => {
    setADGMAll({ data: adgmLicenseByStartupId });
    setFileName(getFileName(adgmLicenseByStartupId?.aDGMLicenseUrl));
  }, [adgmLicenseByStartupId]);

  const handleButton = () => {
    if (hasLicense) {
      if (!identity.isZero(details.currentADGMLicenseID)) {
        updateADGMLicenseByStartupId({ id: startupId, adgmLicense });
      } else {
        createStartupADGMLicense({ ...adgmLicense, startupId });
      }
    }
    updateStartupById({ id: startupId, startup: { ...startup, stageId: startup.stage }, reload: true });
  }

  const beforeLogoUpload = (file: RcFile) => {
    setField({ field: 'imageLink', data: file as Blob });
    return false;
  }

  return (
    <Spin spinning={isLoading}>
      <div className="add-missing-details__container">
        <div className="add-missing-details__container__title">
          <Title size="l" title="Add missing details" />
        </div>
        <div className="add-missing-details__container__rows">
          <Form scrollToFirstError>
            <Input label="Technical license holder name" layout="vertical" required rules={[requiredValidation]}>
              <Input.InputField
                name="name"
                value={startup?.name}
                type="text"
                required
                onChange={(e) => setField({ field: 'name', data: e.target.value })}
              />
            </Input>
            <Input label="Primary industry" layout="vertical" required rules={[requiredValidation]}>
              <Input.Select
                onChange={(data) => setField({ field: 'primarySectorID', data })}
                value={identity.isTruthyNumber(startup?.primarySectorID) ? startup?.primarySectorID : undefined}
                options={sectorOptions}
              />
            </Input>
            <Input label="Technical license holder stage" layout="vertical" required rules={[requiredValidation]}>
              <Input.Select
                onChange={(data) => setField({ field: 'stage', data })}
                value={identity.isTruthyNumber(startup?.stage) ? startup?.stage : undefined}
                options={stageOptions}
                placeholder="Select startup stage"
              />
            </Input>
            <Input label="Website" layout="vertical" required rules={[requiredValidation]}>
              <Input.InputField
                name="website"
                value={startup?.website}
                type="text"
                size="large"
                required
                placeholder="e.g. www.google.com"
                onChange={(e) => setField({ field: 'website', data: e.target.value })}
              />
            </Input>
            <Input label="Number of employees" layout="vertical" required rules={[requiredValidation]}>
              <Input.InputField
                name="numberOfEmployees"
                value={identity.isTruthyNumber(startup?.numberOfEmployees) ? startup?.numberOfEmployees : undefined}
                type="number"
                size="large"
                required
                placeholder="Enter number of employees"
                onChange={(e) => setField({ field: 'numberOfEmployees', data: Number(e.target.value) })}
              />
            </Input>
            <div className="add-missing-details__container__column-inputs">
              <Input layout="vertical" label="Logo" required rules={[requiredValidation]}>
                <EditProfileImage imageLink={startup.imageLink} beforeLogoUpload={beforeLogoUpload} />
              </Input>
              <Input label="About" layout="vertical" required rules={[requiredValidation]}>
                <Input.InputField.TextArea
                  name="about"
                  value={startup?.about}
                  size="large"
                  rows={4}
                  placeholder="Enter description"
                  onChange={(e) => setField({ field: 'about', data: e.target.value })}
                />
              </Input>
              <Input label="Earlier HQ" layout="vertical" required rules={[requiredValidation]}>
                <Input.InputField
                  name="location"
                  value={startup?.location}
                  type="text"
                  size="large"
                  placeholder="Enter earlier HQ"
                  onChange={(e) => setField({ field: 'location', data: e.target.value })}
                />
              </Input>
            </div>
            <div className="add-missing-details__container__column__adgm">
              <h1 className="add-missing-details__container__column__title">Documents</h1>
              <AcceptanceLetter
                fileUrl={startup?.acceptanceLetter}
                letterAcceptanceStatus={startup?.status.name}
              />
              <ADGMLicense
                fileName={fileName}
                setHasLicense={onSetHasLicense}
                hasLicense={hasLicense}
                adgmLicense={adgmLicense}
                setADGMField={setADGMField}
                isAddMissingDetails
                setADGMAll={setADGMAll}
              />
            </div>
            <div className="submit-button">
              <Button disabled={disableAddMissingDetailsSubmitButton(startup, hasLicense, adgmLicense)} type="primary" htmlType="submit" className="submit-button__button" onClick={handleButton}>
                submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default AddTechnicalHolderDetails;
