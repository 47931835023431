import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { setDestroyDrawerOnClose, setIsDrawerLoading, setIsDrawerOpen } from 'redux/actions/workspace';
import config from 'assets/config';
import {
  createTechnicalLicense,
  createTechnicalLicenseADGMLicense,
  CreateTechnicalLicensePayload,
  CreateTechnicalLicenseResponse,
  getInvestmentStagesList,
  getStagesList,
  getTechnicalLicensesCountApi,
  getTechnicalLicensesList,
  getTechnicalLicenseById,
  TechnicalHolderType,
  updateTechnicalLicense,
  getTechnicalLicenseADGMLicenseByTechnicalLicenseId,
  updateAdgmLicenseByTechnicalLicenseId,
  TechnicalLicenseQuery,
  getTechnicalLicensesCountWorkingWithIdCount,
  getTechnicalLicenseIncentiveAgreementById,
  createTechnicalLicenseIncentiveAgreement,
  updateTechnicalLicenseIncentiveAgreement,
  IncentiveAgreementType,
} from 'packages/technical_holder_repository';
import {
  createTechnicalLicenseTeamMember,
  CreateTeamMemberPayload,
  GetTeamMembersParams,
  getTechnicalLicenseMembers,
  getTechnicalLicenseMemberCount,
} from 'packages/people_repository';
import {
  identity,
  isEitherAdmin,
  isPartner,
  ROLES,
  isTechnicalLicense,
} from 'utils';
import { TLHCreateADGMLicensePayload } from 'packages/technical_holder_repository/types/adgm_license';
import {
  selectIncentiveAgreement,
  selectTechnicalLicenseDetails,
  selectTechnicalLicenseForReview,
} from 'redux/selectors/technicalLicenses';
import {
  GetTechnicalLicenseByIdPayload,
  UpdateTechnicalLicensePayload,
  UpdateAdgmLicenseByTechnicalLicenseIdPayload,
} from 'types/reduxTypes/ActionTypes/TechnicalLicensesTypes';
import { GetTeamMembersPayload } from 'types/reduxTypes/ActionTypes/TeamMemberTypes';
import showNotification from 'services/utils/showNotification';
import {
  DataPayload,
  GetAllQueryPayload,
} from 'types/reduxTypes/ActionTypes';
import sleep from 'components/utils/mocks/sleep';
import { PeopleFilters } from 'types/people';
import { UserRoleType } from 'types/auth';
import { ParamsPayload } from 'packages/http_client';
import { getTechnicalLicenseSpsList } from 'packages/service_repository';
import { getAmountSumsBySpAndTechnicalLicenseApi, GetAmountSumsResponse } from 'packages/ai_wallet_repository';
import { ServiceProviderType } from 'types/serviceProviders';
import {
  CreateIncentiveAgreementPayload,
  UpdateIncentiveAgreementPayload,
} from 'types/technicalLicenses';
import { setTechnicalLicenseDetails } from '../../actions/serviceProviders';
import {
  setTechnicalLicenseById,
  setTechnicalLicenses,
  createTechnicalLicenseADGMLicense as createTechnicalLicenseADGMLicenseAC,
  setTechnicalLicenseMembers,
  setStageOptions,
  setInvestmentStageOptions,
  setIsLoading,
  setTechnicalLicensesCount,
  setTeamMembersCount,
  getTechnicalLicenseById as reduxGetTechnicalLicenseById,
  getTechnicalLicenses,
  setADGMLicenseByTechnicalLicenseId,
  setIncentiveAgreementById,
  setTechnicalLicenseServiceProviders,
  setSumsForServiceProviders,
  updateTechnicalLicenseById,
  setTechnicalLicenseForReview,
  types,
} from '../../actions/technicalLicense';
import {
  selectPartnerId,
  selectUserServiceProviderId,
  selectUserTechnicalLicenseId,
  selectUserType,
  selectUserTechnicalLicense,
} from '../../selectors/auth';
import { selectIsRowTableLayout } from '../../selectors/workspace';
import { defaultTechnicalLicenseIncentiveAgreement } from '../../utils';

function * handleGetTechnicalLicense(action: PayloadAction<TechnicalLicenseQuery>) {
  yield put(setIsLoading({ isLoading: true }));

  const userType: UserRoleType = yield select(selectUserType);
  let query = action.payload;
  switch (userType) {
    case ROLES.partner: {
      const partnerId: number = yield select(selectPartnerId);
      query = { ...query, partnerId, statusID: 7 };
      break;
    }
    case ROLES.serviceProvider: {
      const serviceProviderId: number = yield select(selectUserServiceProviderId);
      query = { ...query, serviceProviderId };
      break;
    }
    default: {
      break;
    }
  }

  const { data: technicalLicenses, error, httpStatus } = yield call(getTechnicalLicensesList, query);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch technical licenses: ${error.message}`, true, httpStatus);
  } else {
    yield put(setTechnicalLicenses({ technicalLicenses }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetTechnicalLicenseById(action: PayloadAction<GetTechnicalLicenseByIdPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { id, owner, isUpdate } = action.payload;
  const selectedTechnicalLicense: TechnicalHolderType = yield select(selectTechnicalLicenseDetails);
  const { id: selectedTechnicalLicenseId } = selectedTechnicalLicense;

  if (!(id === selectedTechnicalLicenseId && identity.isFalsy(isUpdate) && owner === 'service-provider')) {
    const {
      data: technicalLicense,
      error,
      httpStatus,
    } = yield call(getTechnicalLicenseById, id, true);

    if (identity.isObjWithChildren(error)) {
      showNotification(`Unable to fetch Technical license: ${error?.message}`, true, httpStatus);
    } else {
      switch (owner) {
        case 'technical-license': {
          yield put(setTechnicalLicenseById({ technicalLicense }));
          break;
        }
        case 'service-provider': {
          yield put(setTechnicalLicenseDetails({ technicalLicense }));
          break;
        }
        default:
          break;
      }
    }
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleCreateTechnicalLicenseADGMLicense(action: PayloadAction<TLHCreateADGMLicensePayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { technicalLicenseId } = action.payload;
  const { error, httpStatus } = yield call(createTechnicalLicenseADGMLicense, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Technical license ADGM License uploaded successfully');
    yield put(reduxGetTechnicalLicenseById({ id: technicalLicenseId, owner: 'technical-license', isUpdate: false }));
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleCreateNewTechnicalLicense(action: PayloadAction<DataPayload<CreateTechnicalLicensePayload>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { data } = action.payload;

  const {
    error: technicalLicenseError,
    data: technicalLicenseId,
  }: CreateTechnicalLicenseResponse = yield call(createTechnicalLicense, data);

  if (identity.isObjWithChildren(technicalLicenseError) || identity.isFalsy(technicalLicenseId)) {
    showNotification(technicalLicenseError?.message || 'Error creating a Technical license.', true);
    yield put(setIsDrawerLoading({ isLoading: false }));
  } else {
    const {
      firstName,
      lastName,
      email,
      jobTitle,
      adgmLicense,
      hasLicense,
    } = data;

    const createADGMLicensePayload: TLHCreateADGMLicensePayload = {
      ...adgmLicense,
      technicalLicenseId: technicalLicenseId as number,
    };

    const createTeamMemberPayload: CreateTeamMemberPayload = {
      technicalLicenseId: technicalLicenseId as number, firstName, lastName, email, jobTitle,
    }

    const { error } = yield call(createTechnicalLicenseTeamMember, createTeamMemberPayload);

    if (hasLicense) {
      yield put(createTechnicalLicenseADGMLicenseAC(createADGMLicensePayload));
    }

    if (error) {
      showNotification(error.message, true);
    }

    showNotification('Technical license Created successfully');

    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    yield put(setIsDrawerLoading({ isLoading: false }));
    const userType: UserRoleType = yield select(selectUserType);
    const isRowTableLayout: boolean = yield select(selectIsRowTableLayout);

    const technicalLicenseQuery: TechnicalLicenseQuery = {
      limit: (isRowTableLayout && !isPartner(userType)) ? config.TABLE_DEFAULT_LIMIT : config.GRID_TABLE_DEFAULT_LIMIT,
      offset: 0,
      isPreload: true,
      filters: {},
    };

    yield put(getTechnicalLicenses(technicalLicenseQuery));
  }
}

function * handleGetTechnicalLicenseMembers(action: PayloadAction<GetTeamMembersPayload>) {
  const {
    id: technicalLicenseId, offset, limit, filters,
  } = action.payload;

  yield put(setIsLoading({ isLoading: true }));

  const { data: members, error, httpStatus } = yield call(getTechnicalLicenseMembers, {
    technicalLicenseId, offset, limit, filters,
  });

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch Technical license team members: ${error.message}`, true, httpStatus);
  } else {
    yield put(setTechnicalLicenseMembers({ members }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetTeamMembersCount(action: PayloadAction<PeopleFilters>) {
  const technicalLicenseId: number = yield select(selectUserTechnicalLicenseId);
  const params: GetTeamMembersParams = { technicalLicenseId, filters: action.payload };
  const { data, error, httpStatus } = yield call(getTechnicalLicenseMemberCount, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setTeamMembersCount({ data: data[0].id__count }));
  }
}

const reloadOnSubmit = async () => {
  await sleep(2000)
  window.location.reload();
}

function * handleUpdateTechnicalLicenseById(action: PayloadAction<UpdateTechnicalLicensePayload>) {
  const { id, technicalLicense, reload } = action.payload;
  yield put(setIsLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(updateTechnicalLicense, id, technicalLicense);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error updating Technical license: ${error.message}`, true, httpStatus);
  } else {
    showNotification(`Technical license ${id} updated successfully`);
    yield all([
      put(reduxGetTechnicalLicenseById({ id, owner: 'technical-license', isUpdate: false })),
      put(setTechnicalLicenses({ technicalLicenses: [] })),
    ])
  }

  yield put(setIsLoading({ isLoading: false }));

  if (reload && httpStatus === 200) {
    yield call(reloadOnSubmit);
  }
}

function * handleGetStagesTLH() {
  const { data, error, httpStatus } = yield call(getStagesList);

  if (identity.isObjWithChildren(error)) {
    showNotification(error.message, true, httpStatus);
  } else {
    yield put(setStageOptions({ data }));
  }
}

function * handleGetInvestmentStagesTLH() {
  const { data, error, httpStatus } = yield call(getInvestmentStagesList);
  if (identity.isObjWithChildren(error)) {
    showNotification(error.message, true, httpStatus);
  } else {
    yield put(setInvestmentStageOptions({ data }));
  }
}

function * handleGetTechnicalLicensesCount(action: PayloadAction<TechnicalLicenseQuery>) {
  let params: TechnicalLicenseQuery = action.payload;

  const userType: UserRoleType = yield select(selectUserType);
  let apiCall = getTechnicalLicensesCountWorkingWithIdCount;
  switch (userType) {
    case ROLES.partner: {
      const partnerId: number = yield select(selectPartnerId);
      params = { ...params, partnerId, fieldSelection: ['TECHNICAL_LICENSE_id'] };
      break;
    }
    case ROLES.serviceProvider: {
      const serviceProviderId: number = yield select(selectUserServiceProviderId);
      params = { ...params, serviceProviderId, fieldSelection: ['TECHNICAL_LICENSE_id'] };
      break;
    }
    default: {
      apiCall = getTechnicalLicensesCountApi;
      break;
    }
  }
  const { data: technicalLicenseCount, error, httpStatus } = yield call(apiCall, params);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch Technical license count: ${error.message}`, true, httpStatus);
  } else {
    yield put(setTechnicalLicensesCount({ technicalLicenseCount }));
  }
}

function * handleGetADGMLicenseByTechnicalLicenseId(action: PayloadAction<GetTechnicalLicenseByIdPayload>) {
  const { id } = action.payload;

  const {
    data: adgmLicense,
    error,
    httpStatus,
  } = yield call(getTechnicalLicenseADGMLicenseByTechnicalLicenseId, id, true);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch adgmLicense: ${error.message}`, true, httpStatus);
  } else {
    yield put(setADGMLicenseByTechnicalLicenseId({ data: adgmLicense }));
  }
}

function * handleUpdateADGMLicenseByTechnicalLicenseId(action:
  PayloadAction<UpdateAdgmLicenseByTechnicalLicenseIdPayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { id, adgmLicense } = action.payload;
  const { error, httpStatus } = yield call(updateAdgmLicenseByTechnicalLicenseId, id, adgmLicense)
  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to update adgmLicense: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Technical License ADGM License uploaded successfully');
    yield put(setADGMLicenseByTechnicalLicenseId({ data: adgmLicense }));
    yield put(reduxGetTechnicalLicenseById({ id, owner: 'technical-license', isUpdate: false }));
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleGetIncentiveAgreementById() {
  let technicalLicense: TechnicalHolderType;
  const userType: UserRoleType = yield select(selectUserType);

  if (isTechnicalLicense(userType)) {
    technicalLicense = yield select(selectUserTechnicalLicense);
  } else {
    technicalLicense = yield select(selectTechnicalLicenseDetails);
  }

  const id: number = technicalLicense?.currentIncentiveAgreementID!;

  if (identity.isTruthyNumber(id)) {
    const { error, httpStatus, data } = yield call(getTechnicalLicenseIncentiveAgreementById, id);

    if (identity.isObjWithChildren(error)) {
      showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
    } else {
      yield put(setIncentiveAgreementById({ data }));
    }
  } else {
    yield put(setIncentiveAgreementById({ data: defaultTechnicalLicenseIncentiveAgreement }));
  }
}

function * handleGetTechnicalLicenseServiceProviders(action: PayloadAction<GetAllQueryPayload<ParamsPayload>>) {
  yield put(setIsLoading({ isLoading: true }));

  const { data, error, httpStatus } = yield call(getTechnicalLicenseSpsList, action.payload.query);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching service providers: ${error.message}`, true, httpStatus);
  } else {
    yield all([
      put(setTechnicalLicenseServiceProviders({ data })),
      call(handleGetSumsForServiceProviders, data, action.payload.query.technicalLicenseId as number),
    ]);
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetSumsForServiceProviders(data: ServiceProviderType[], technicalLicenseId: number) {
  const spSums: GetAmountSumsResponse[] = [];

  for (const i in data) {
    const { data: sums, error } = yield call(
      getAmountSumsBySpAndTechnicalLicenseApi,
      { serviceProviderId: data[i].id, technicalLicenseId },
    );

    if (identity.isObjWithChildren(error) || !identity.isObjWithChildren(sums)) {
      spSums.push({ serviceProviderId: data[i].id, amountConsumed: 'AED 0.00', amountBlocked: 'AED 0.00' });
    } else {
      spSums.push({ ...sums, serviceProviderId: data[i].id });
    }
  }

  yield put(setSumsForServiceProviders({ data: spSums }));
}

function * handleCreateIncentiveAgreementTLH(action: PayloadAction<CreateIncentiveAgreementPayload>) {
  const { data } = action.payload;
  yield put(setIsLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(createTechnicalLicenseIncentiveAgreement, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Incentive agreement sent for approval.');
    yield all([
      put(reduxGetTechnicalLicenseById({ id: data.technicalLicenseID, owner: 'technical-license', isUpdate: false })),
      call(handleGetIncentiveAgreementById),
      put(setIsDrawerOpen({ isDrawerOpen: false })),
    ]);
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdateIncentiveAgreementTLH(action: PayloadAction<UpdateIncentiveAgreementPayload>) {
  const { data, id } = action.payload;
  yield put(setIsLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(updateTechnicalLicenseIncentiveAgreement, data, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    const userType: UserRoleType = yield select(selectUserType);
    if (isTechnicalLicense(userType)) {
      showNotification('Incentive agreement sent for approval.');
      yield all([
        put(setIsDrawerOpen({ isDrawerOpen: false })),
        put(updateTechnicalLicenseById({
          id: data.technicalLicenseID,
          technicalLicense: { incentiveAgreementApprovedStatus: 0 },
          reload: true,
        })),
      ]);
    }
    yield call(handleGetIncentiveAgreementById);
    if (isEitherAdmin(userType)) {
      const currentIncentiveAgreement: IncentiveAgreementType = yield select(selectIncentiveAgreement);
      const technicalLicense: TechnicalHolderType = yield select(selectTechnicalLicenseForReview);
      yield put(setTechnicalLicenseForReview({ technicalLicense: { ...technicalLicense, currentIncentiveAgreement } }));
    }
  }
  yield put(setIsLoading({ isLoading: false }));
}

export default function * technicalLicenseSagas() {
  yield all([
    takeLatest(types.UPDATE_ADGM_LICENSE_BY_TECHNICAL_LICENSE_ID, handleUpdateADGMLicenseByTechnicalLicenseId),
    takeLatest(types.CREATE_TECHNICAL_LICENSE_ADGM_LICENSE, handleCreateTechnicalLicenseADGMLicense),
    takeLatest(types.GET_ADGM_LICENSE_BY_TECHNICAL_LICENSE_ID, handleGetADGMLicenseByTechnicalLicenseId),
    takeLatest(types.GET_TECHNICAL_LICENSES, handleGetTechnicalLicense),
    takeLatest(types.GET_TECHNICAL_LICENSES_COUNT, handleGetTechnicalLicensesCount),
    takeLatest(types.GET_TECHNICAL_LICENSE_BY_ID, handleGetTechnicalLicenseById),
    takeLatest(types.CREATE_TECHNICAL_LICENSE, handleCreateNewTechnicalLicense),
    takeLatest(types.GET_TECHNICAL_LICENSE_MEMBERS, handleGetTechnicalLicenseMembers),
    takeLatest(types.UPDATE_TECHNICAL_LICENSE_BY_ID, handleUpdateTechnicalLicenseById),
    takeLatest(types.GET_TECHNICAL_LICENSE_STAGES, handleGetStagesTLH),
    takeLatest(types.GET_INVESTMENT_STAGES, handleGetInvestmentStagesTLH),
    takeLatest(types.GET_TECHNICAL_LICENSE_TEAM_MEMBERS_COUNT, handleGetTeamMembersCount),
    takeLatest(types.GET_INCENTIVE_AGREEMENT_BY_ID, handleGetIncentiveAgreementById),
    takeLatest(types.GET_TECHNICAL_LICENSE_SERVICE_PROVIDERS, handleGetTechnicalLicenseServiceProviders),
    takeLatest(types.CREATE_INCENTIVE_AGREEMENT, handleCreateIncentiveAgreementTLH),
    takeLatest(types.UPDATE_INCENTIVE_AGREEMENT, handleUpdateIncentiveAgreementTLH),
  ]);
}
