import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Popover } from 'antd';

import { ProfilePopoverProps } from 'types/reduxTypes/ActionTypes/WorkspaceTypes';
import routes from 'routes';
import { UserIcon } from 'assets/icons';
import { isEitherAdmin, isStartup } from 'utils';
import { RenderIf } from 'components/components';

import './ProfilePopover.scss';

const ProfilePopover = (props: ProfilePopoverProps) => {
  const {
    actions,
    loggedInUser,
    userType,
    isVisible,
  } = props;

  const onLogout = () => {
    actions.logout();
  }

  const renderTitle = () => {
    if (isStartup(userType)) return 'Startup';
    return 'Company'
  }

  const renderRedirect = () => {
    if (isStartup(userType)) return routes.startupProfileUrl;
    return routes.companyProfileUrl
  }

  const content = (
    <div className="profile-content-col">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div onClick={() => actions.toggleUserProfile()}>
        <Link className="avatar-options" to={routes.myProfileUrl}>My profile</Link>
      </div>
      <RenderIf condition={!isEitherAdmin(userType)}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div onClick={() => actions.toggleUserProfile()}>
          <Link
            className="avatar-options"
            to={renderRedirect()}
          >
            {`${renderTitle()} profile`}
          </Link>
        </div>
      </RenderIf>

      <Link className="sign-out" onClick={onLogout} type="link" to={routes.loginUrl}>SIGN OUT</Link>
    </div>
  );

  return (
    <Popover
      overlayClassName="popover-inner-style"
      placement="bottomRight"
      content={content}
      trigger="click"
      open={isVisible}
    >
      <Avatar
        className="avatar"
        size="default"
        icon={<UserIcon />}
        src={loggedInUser?.photo}
        onClick={() => actions.toggleUserProfile()}
      />
    </Popover>
  );
};

export default ProfilePopover;
