import React, { useMemo } from 'react';

import { identity } from 'utils';
import { TableProps } from 'types/componentTypes';
import { GridTable, RowTable, CustomPagination } from './components';
import { SearchBar, FilterButtons, RenderIf } from '..';
import { getFilterButtonsData } from '../FilterButtons';
import StaticNotification from '../Notification/StaticNotification';

import './Table.scss';

const Table = (props: TableProps) => {
  const {
    isRowTableLayout,
    limit,
    tableLocation,
    showCustomPagination,
    currentPage,
    setCurrentPage,
    setLimit,
    sectorOptions,
    total,
  } = props;

  const isDefaultTableLocation = useMemo(() => tableLocation === 'default', [tableLocation]);
  const handleCloseFilter = () => props?.actions?.setFilterShown({ isFilterShown: false })

  const filters = useMemo(() => (
    isDefaultTableLocation
      ? []
      : getFilterButtonsData(sectorOptions, tableLocation)
  ), [sectorOptions, isDefaultTableLocation]);

  return (
    <div className="table">
      <SearchBar
        showSearchBar={props.showSearchBar}
        leftContent={props.leftContent}
        showDisplayButtons={props.showDisplayButtons}
        placeholder={props.placeholder}
        addButtonText={props.addButtonText}
        onAddButtonClick={props.onAddButtonClick}
        showTypeButton={props.showTypeButton}
        onClickFilterButton={props.onClickFilterButton}
        infoText={props.infoText}
        showSearchField={props.showSearchField}
        showAllCategories={props.showAllCategories}
        showFilterButton={props.showFilterButton}
        filtersActive={props.filtersActive}
        setSearchString={props.setSearchString}
        searchString={props.searchString}
        filterButtonType={props.filterButtonType}
        filterDropdownElements={props.filterDropdownElements}
      />
      <RenderIf condition={!isDefaultTableLocation}>
        <FilterButtons
          dataSource={identity.isEmptyArray(sectorOptions) ? [] : filters}
          setFilter={props.setFilter}
          activeFilters={props.activeFilters}
        />
      </RenderIf>
      <RenderIf condition={props.filtersActive}>
        <StaticNotification type="warning" message="You are currently viewing filtered items. To view all items clear filters." />
      </RenderIf>
      <div onClick={handleCloseFilter} onKeyDown={handleCloseFilter}>
        {
          isRowTableLayout
            ? (
              <RowTable
                isLoading={props.isLoading}
                columns={props.columns}
                dataSource={props.dataSource}
                onRow={props.onRow}
                rowSelection={props.rowSelection}
                rowKey={props.rowKey}
                showHeader={props.showHeader}
              />
            )
            : (
              <GridTable
                isLoading={props.isLoading}
                columns={props.columns}
                dataSource={props.dataSource}
                onRow={props.onRow}
                rowSelection={props.rowSelection}
                rowKey={props.rowKey}
                onGridCardClick={props.onGridCardClick}
                className="grid-table"
                numOfCard={props.numOfCard}
                component={props.component}
                onDeleteService={props.onDeleteService}
                onEditService={props.onEditService}
                firstButtonTitle={props.firstButtonTitle}
                secondButtonTitle={props.secondButtonTitle}
              />
            )
        }
        <RenderIf condition={showCustomPagination}>
          <CustomPagination
            limit={limit}
            setLimit={setLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            total={total}
            gridLayout={!isRowTableLayout}
          />
        </RenderIf>
      </div>

    </div>
  )
}

Table.defaultProps = {
  tableLocation: 'default',
  showCustomPagination: false,
  limit: 1,
  sectorOptions: [],
  isLoading: false,
}

export default Table;
