import { API_ROOT } from 'packages/http_client/utils'

export const STARTUP_TEAM_MEMBER_API = `${API_ROOT}/startupteammember`;

export const ANGEL_INVESTOR_MEMBER_API = `${API_ROOT}/angelinvestormember`;

export const TECHNICAL_LICENSE_MEMBER_API = `${API_ROOT}/technicallicensemember`;

export const SERVICE_PROVIDER_MEMBER_API = `${API_ROOT}/serviceprovidermember`;

export const HUB_71_EMPLOYEE_API = `${API_ROOT}/hubseventyone`;

export const INVOLVEMENT_TYPE_API = `${API_ROOT}/involvementtype`;

export const PARTNER_MEMBER_API = `${API_ROOT}/partnermember`;
