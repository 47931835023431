import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import showNotification from 'services/utils/showNotification';
import { getTechnicalLicenseMemberById } from 'packages/people_repository';
import { IdPayload } from 'types/reduxTypes/ActionTypes';
import { identity } from 'utils';
import {
  setTechnicalLicenseMemberById,
  types,
} from '../../actions/technicalLicenseMemberDetails';

export function * handleGetTechnicalLicenseMemberById(action: PayloadAction<IdPayload>) {
  const { id } = action.payload;

  const { data: member, error, httpStatus } = yield call(getTechnicalLicenseMemberById, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  }

  yield put(setTechnicalLicenseMemberById({ member }));
}

export default function * technicalLicenseMemberDetailsSagas() {
  yield all([
    takeLatest(types.GET_TECHNICAL_LICENSE_MEMBER_BY_ID, handleGetTechnicalLicenseMemberById),
  ]);
}
