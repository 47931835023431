import React, { useEffect, useMemo } from 'react';
import moment from 'moment';

import { identity } from 'utils';
import { Input } from 'components/components';
import { ADGMLicenseDetailsDrawerProps } from 'types/technicalLicenses/editTechnicalLicense';
import { NOT_SPECIFIED } from '../../../CohortDetails/utils/constants';
import { formatSlashMDY } from '../../../utils/constants/formatters';
import { getTechnicalLicenseFile } from '../../utils';
import { DetailsDrawerRow, TLHADGMLicenseUploadForm } from '..';

import './ADGMLicenseDetailsDrawer.scss';

const TLHADGMLicenseDetailsDrawer = (props: ADGMLicenseDetailsDrawerProps) => {
  const { adgm, userTechnicalLicenseId, id } = props;
  const isCreateOrEditMode = useMemo(() => !identity.isTruthyString(adgm?.aDGMLicenseUrl)
    || (identity.isZero(adgm?.approvedRejectedStatus) && identity.isTruthyString(adgm?.approvedRejectedDate)), [adgm])

  const technicalLicenseId = useMemo(
    () => (identity.isTruthyNumber(userTechnicalLicenseId) ? userTechnicalLicenseId : id),
    [id, userTechnicalLicenseId],
  );

  useEffect(() => {
    props.actions.getADGMLicenseByTechnicalLicenseId({ id: technicalLicenseId });
  }, [technicalLicenseId]);

  if (isCreateOrEditMode) {
    return <TLHADGMLicenseUploadForm adgm={adgm} technicalLicenseId={technicalLicenseId} />;
  }

  return (
    <div className="adgm-license-details-drawer">
      <DetailsDrawerRow label="ADGM License No." item={adgm.aDGMLicenseNo || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Legal Name" item={adgm.legalADGMName || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Legal Signatory Name" item={adgm.legalSignatoryName || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Legal Signatory Title" item={adgm.legalSignatoryTitle || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Legal Signatory Email" item={adgm.legalSignatoryEmail || NOT_SPECIFIED} />
      <DetailsDrawerRow label="Registered WeWork Address" item={adgm.address || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Issue Date" item={identity.isTruthyString(adgm.issueDate) ? moment(adgm.issueDate).format(formatSlashMDY) : NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Expiry Date" item={identity.isTruthyString(adgm.expiryDate) ? moment(adgm.expiryDate).format(formatSlashMDY) : NOT_SPECIFIED} />
      <Input.DraggerUpload
        previewType="image"
        defaultFileList={[...getTechnicalLicenseFile(adgm?.aDGMLicenseUrl!)]}
        isRoundableButton
      />
    </div>
  );
}

export default TLHADGMLicenseDetailsDrawer;
