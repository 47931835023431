import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { updateTechnicalLicenseMemberById } from 'redux/actions/people';
import { selectMemberDetails } from 'redux/selectors/technicalLicenseMembers';
import { selectNationalities } from 'redux/selectors/workspace';
import { getNationalities } from 'redux/actions/workspace';
import { selectMyProfileId, selectUserType } from 'redux/selectors/auth';
import { getMyProfileData } from 'redux/actions/auth';
import { getTechnicalLicenseMemberById, getPartnerById } from 'redux/actions/technicalLicenseMemberDetails';

const mapStateToProps = (state: StateType) => ({
  selectedMember: selectMemberDetails(state),
  nationalities: selectNationalities(state),
  userType: selectUserType(state),
  profileId: selectMyProfileId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getTechnicalLicenseMemberById,
    updateTechnicalLicenseMemberById,
    getPartnerById,
    getNationalities,
    getMyProfileData,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
