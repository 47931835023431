import { createSelector } from 'reselect';

import { TechnicalLicenseMembersStateType } from '../../../types/reduxTypes/StateTypes';
import { StateType } from '../../../types';

const selectMembersState = (state: StateType) => state.technicalLicenseMembersReducer;

export const selectEventFilterType = createSelector(
  selectMembersState,
  (state: TechnicalLicenseMembersStateType) => state.selectedMember.eventFilter,
);

export const selectMemberDetails = createSelector(
  selectMembersState,
  (state: TechnicalLicenseMembersStateType) => state.selectedMember.data,
);

export const selectPastEvents = createSelector(
  selectMembersState,
  (state: TechnicalLicenseMembersStateType) => state.selectedMember.pastEvents,
);

export const selectUpcomingEvents = createSelector(
  selectMembersState,
  (state: TechnicalLicenseMembersStateType) => state.selectedMember.upcomingEvents,
);
