import { connect } from 'react-redux';
import {
  Action, bindActionCreators, Dispatch,
} from 'redux';

import { setIsDrawerOpen, setDrawerBody, setFilterShown } from 'redux/actions/workspace';
import {
  selectAngelInvestorTeamMembers,
  selectAngelInvestorTeamMembersCount,
  selectIsLoading as selectAIIsLoading,
} from 'redux/selectors/angelInvestors';
import { selectStartupTeamMembers, selectStartupTeamMembersCount, selectIsLoading } from 'redux/selectors/startups';
import { StateType } from 'types';
import { setPeopleFilters } from 'redux/actions/people';
import { selectPeopleFilters, selectPeopleFiltersActive } from 'redux/selectors/people';
import {
  getAngelInvestorMembers,
  getTeamMembersCount as getAngelInvestorTeamMembersCount,
  getAngelInvestorById,
} from 'redux/actions/angelInvestors';
import { getStartupTeamMembers, getTeamMembersCount } from 'redux/actions/startups';
import { selectUserStartupId, selectUserAngelInvestorId, selectUserType } from 'redux/selectors/auth';
import { selectIsRowTableLayout, selectIsFilterShown } from 'redux/selectors/workspace';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  teamMembers: selectStartupTeamMembers(state),
  aITeamMembers: selectAngelInvestorTeamMembers(state),
  startupId: selectUserStartupId(state),
  angelInvestorId: selectUserAngelInvestorId(state),
  teamMembersCount: selectStartupTeamMembersCount(state),
  aITeamMembersCount: selectAngelInvestorTeamMembersCount(state),
  isLoading: selectIsLoading(state),
  aIIsLoading: selectAIIsLoading(state),
  isRowTableLayout: selectIsRowTableLayout(state),
  isFilterShown: selectIsFilterShown(state),
  filtersActive: selectPeopleFiltersActive(state),
  filters: selectPeopleFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsDrawerOpen,
    setDrawerBody,
    setFilterShown,
    getStartupTeamMembers,
    getTeamMembersCount,
    getAngelInvestorMembers,
    getAngelInvestorTeamMembersCount,
    getAngelInvestorById,
    setPeopleFilters,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
