import React, { useEffect } from 'react';

import { identity } from 'utils';
import routes from 'routes';
import { Details } from 'components/MemberDetails/components';
import { TechnicalLicenseUserProps } from 'types/user';

const TechnicalLicenseUser = (props: TechnicalLicenseUserProps) => {
  const { user, currentUser } = props;

  useEffect(() => {
    if (identity.isPositiveNumber(currentUser.technicalLicenseId)) {
      props.actions.getMyProfileData();
    }
  }, []);

  if (!identity.isPositiveNumber(user.id)) {
    return null;
  }

  return (
    <div className="my-profile">
      <Details user={user} link={routes.editMyProfileUrl} />
    </div>
  );
}

export default TechnicalLicenseUser;
