import moment from 'moment';
import { createSelector } from 'reselect';

import { StateType } from 'types';
import { RequestStateType } from 'types/reduxTypes/StateTypes';
import { ServiceRequestType } from 'packages/service_request_repository';

const selectRequestState = (state: StateType) => state.requestReducer;

export const selectShowInvoiceFields = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.showInvoiceFields,
);

export const selectRequests = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.requests,
);

export const selectRequestDetails = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.details.data,
);

export const selectRequestDetailsActiveTab = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.details.activeTab,
);

export const selectRequestComments = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.details.comments,
);

export const selectRequestInfo = createSelector(
  selectRequestDetails,
  (state: ServiceRequestType) => {
    const {
      service,
      applicationDate: date,
      status,
      id,
    } = state;
    return {
      service: service?.name,
      requestNumber: id,
      date,
      status,
      requestType: `${service?.category} request`,
      id,
    };
  },
);

export const selectRequestProposal = createSelector(
  selectRequestDetails,
  (state: ServiceRequestType) => {
    const { service } = state;
    return {
      proposalTitle: '',
      proposalDocument: '',
      otherDetails: '',
      proposalDuration: '',
      priceFrom: service?.priceFrom,
      priceTo: service?.priceTo,
      offerPrice: service?.offerPrice,
      priceType: service?.priceType,
    };
  },
);

export const selectPersonDetails = createSelector(
  selectRequestDetails,
  () => ({
    id: 0,
    email: 'not specified',
    name: 'not specified',
    phone: 'not specified',
    role: 'not specified',
    imageLink: '',
  }),
);

export const selectStartupDetails = createSelector(
  selectRequestDetails,
  (state: ServiceRequestType) => state.startup,
);

export const selectIsLoading = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.isLoading,
);

export const selectRequestStatus = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.items[0]?.serviceRequest?.status,
);

export const selectRequestId = createSelector(
  selectRequestDetails,
  (state: ServiceRequestType) => state?.id,
);

export const selectIncentiveRequestDetails = createSelector(
  selectRequestDetails,
  (state: ServiceRequestType) => {
    const {
      service,
      status,
      applicationDate: date,
      startup,
      id,
    } = state;

    return {
      requestNumber: id,
      category: service?.category,
      name: service?.name,
      description: service?.about,
      title: 'not specified',
      duration: 'not specified',
      otherDetails: 'not specified',
      proposalDocument: '',
      status,
      date: moment(date),
      person: {
        id: 0,
        name: 'not specified',
        email: 'not specified',
        phone: 'not specified',
        imageLink: '',
        role: 'not specified',
      },
      startup,
      type: `${service?.category} request`,
      service,
      to: 'not specified',
    };
  },
);

export const selectCurrentTab = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.currentTab,
);

export const selectRequestsCount = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.count,
);

export const selectUnsentRequests = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.unsentRequests,
);

export const selectHighlightRequest = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.highlightRequest,
);

export const selectServicesBySp = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.servicesBySP,
);

export const selectHighlightUnsentRequest = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.highlightUnsentRequest,
);

export const selectAddedRequestsCount = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.addedRequestsCount,
);

export const selectRequestItems = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.items,
);

export const selectPartnerRequestDetail = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.details.partnerRequestData,
);

export const selectCommentsLoading = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.details.commentsLoading,
);

export const selectPartnerRequestId = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.partnerRequestId,
);

export const selectServiceRequestId = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.serviceRequestId,
);

export const selectServiceRequestDocuments = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.details.documents,
);

export const selectRequestOverviewFilters = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.filters,
);

export const selectRequestsFiltersActive = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.filtersActive,
);

//  Request Tabs Filter
export const selectCurrentUserTab = createSelector(
  selectRequestState,
  (state: RequestStateType) => state.currentUserTab,
);
