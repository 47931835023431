import { connect } from 'react-redux';
import { selectUserType } from 'redux/selectors/auth';

import {
  selectServiceProviderCurrentStartup,
  selectServiceProviderCurrentStartupRequests,
} from '../../../../redux/selectors/serviceProviders';
import { StateType } from '../../../../types';

const mapStateToProps = (state: StateType) => ({
  startup: selectServiceProviderCurrentStartup(state),
  requests: selectServiceProviderCurrentStartupRequests(state),
  userType: selectUserType(state),
});

export default connect(mapStateToProps);
