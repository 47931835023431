import React from 'react';

import { ProfileProps } from 'types/user';
import { ROLES } from 'utils';
import EditAngelInvestor from '../../EditAngelInvestor';
import EditTechnicalLicense from '../../EditTechnicalLicense';
import EditStartup from '../../EditStartup';
import EditServiceProvider from '../../EditServiceProvider';
import EditPartner from '../../EditPartner';

const EditCompanyProfile = (props: ProfileProps) => {
  const { userType } = props;

  switch (userType) {
    case ROLES.startupFounder: {
      return <EditStartup />;
    }
    case ROLES.technicalLicense: {
      return <EditTechnicalLicense />;
    }
    case ROLES.angelInvestor: {
      return <EditAngelInvestor />;
    }
    case ROLES.serviceProvider: {
      return <EditServiceProvider />;
    }
    case ROLES.partner: {
      return <EditPartner />;
    }
    default: {
      return null;
    }
  }
}

export default EditCompanyProfile;
