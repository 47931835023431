import React, { useMemo, useState } from 'react';
import { Button, Layout, Menu } from 'antd';
import classnames from 'classnames';
import { useHistory } from 'react-router';

import { SidebarType } from 'types/sidebar/SidebarProps';
import logo from 'assets/images';
import { SidebarCollapseIcon, SidebarExpandIcon } from 'assets/icons';
import { RenderIf } from 'components/components';
import sidebarOptionsByRole from './utils/constants';

import './Sidebar.scss';

const { Sider } = Layout;

const Sidebar = (props: SidebarType) => {
  const { userType, location } = props;
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const history = useHistory();

  const currentLocation = useMemo(() => location.pathname.split('/')[1], [location]);

  const items = useMemo(() => sidebarOptionsByRole?.map((item) => (!item.acceptedTypes.includes(userType)
    ? null
    : ({
      key: item.key,
      label: item.label,
      icon: item.icon,
      onClick: () => history.push(item.path),
    }))), [userType]);

  const handleHidePopovers = () => {
    if (props.isOpen) {
      props.actions.toggleNotifications()
    }

    if (props.isFilterShown) {
      props.actions.setFilterShown({ isFilterShown: false })
    }
  }

  return (
    <div className="sidebar-container" onClick={handleHidePopovers} onKeyDown={handleHidePopovers}>
      <div className="sidebar-container__logo-container">
        <RenderIf condition={!collapsed}>
          <img className="sidebar-container__logo" src={logo} alt="hub71-logo" />
        </RenderIf>
      </div>
      <Sider
        trigger={null}
        className={classnames('sidebar', collapsed ? 'sidebar-collapsed' : 'sidebar-expanded')}
        collapsible
        collapsed={collapsed}
      >
        <Button className="sidebar__menu-trigger" onClick={() => setCollapsed(!collapsed)}>
          { collapsed ? <SidebarExpandIcon /> : <SidebarCollapseIcon /> }
        </Button>
        <Menu
          className="sidebar__menu"
          mode="inline"
          defaultSelectedKeys={['/']}
          selectedKeys={[currentLocation]}
          items={items}
        />
      </Sider>
    </div>
  );
}

export default Sidebar;
