import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import {
  selectIsLoading,
  selectRequests,
  selectUnsentRequests,
  selectCurrentTab,
  selectCurrentUserTab,
  selectRequestsCount,
  selectRequestOverviewFilters,
  selectRequestsFiltersActive,
} from 'redux/selectors/request';
import {
  getRequests,
  setCurrentTab,
  getUnsentRequests,
  setCurrentUserTypeTab,
} from 'redux/actions/request';
import { selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  requests: selectRequests(state),
  userType: selectUserType(state),
  isLoading: selectIsLoading(state),
  currentTab: selectCurrentTab(state),
  currentUserTab: selectCurrentUserTab(state),
  unsentRequests: selectUnsentRequests(state),
  count: selectRequestsCount(state),
  filters: selectRequestOverviewFilters(state),
  filtersActive: selectRequestsFiltersActive(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getRequests,
    setCurrentTab,
    getUnsentRequests,
    setCurrentUserTypeTab,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
