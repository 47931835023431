import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';
import { getArticleById } from 'redux/actions/articles';
import { selectArticleDetails } from 'redux/selectors/articles';

import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  articleDetails: selectArticleDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getArticleById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
