import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import {
  selectTechnicalLicenseDetails,
} from 'redux/selectors/technicalLicenses';
import {
  getTechnicalLicenseById,
  updateTechnicalLicenseById,
} from 'redux/actions/technicalLicense';
import { selectUserTechnicalLicenseId, selectUserType } from 'redux/selectors/auth';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  selectedTechnicalLicense: selectTechnicalLicenseDetails(state),
  userTechnicalLicenseId: selectUserTechnicalLicenseId(state),
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getTechnicalLicenseById,
    updateTechnicalLicenseById,
    setDrawerBody,
    setIsDrawerOpen,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
