import React from 'react';

import { Navbar } from 'components/LayoutWithSidebar/components';
import AddTechnicalHolderDetails from 'components/AddTechnicalHolderDetails';

const AddTLHDetailsWithNavbar = () => (
  <div>
    <Navbar />
    <AddTechnicalHolderDetails />
  </div>
);

export default AddTLHDetailsWithNavbar;
