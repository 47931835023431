import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';
import { selectCurrentUserTab, selectRequestOverviewFilters } from 'redux/selectors/request';
import { setCurrentUserTypeTab } from 'redux/actions/request';

const mapStateToProps = (state: StateType) => ({
  filters: selectRequestOverviewFilters(state),
  userType: selectUserType(state),
  currentUserTab: selectCurrentUserTab(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setCurrentUserTypeTab,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
