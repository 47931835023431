import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom';

import './ArticleDetails.scss'
import moment from 'moment'
import { formatSpaceMDY } from 'components/utils/constants/formatters'
import { ArticleDetailsProps } from 'packages/articles_repository/types/articlesOverview';
import { RenderIf } from 'components/components';
import { identity } from 'utils';

const ArticleDetails = (props: ArticleDetailsProps) => {
  const params: { id?: string } = useParams();
  const { id } = params;
  const { actions, articleDetails } = props;

  const {
    getArticleById,
  } = actions

  useEffect(() => {
    getArticleById({ id: Number(id) });
  }, []);

  const articlesData = useMemo(() => {
    const date = moment('2020-08-01T05:34:47+00:00').format(formatSpaceMDY)
    return { ...articleDetails, date }
  }, [articleDetails]);

  const {
    title,
    subTitle,
    readTime,
    banner,
    content,
    date,
  } = articlesData

  return (
    <div className="article-container" key={id}>
      <RenderIf condition={!identity.isEmptyString(title)}>
        <h1 className="text text-l text-700 article-title">{title}</h1>
      </RenderIf>
      <div className="article-meta-details">
        <ul>
          <li>
            By:
            {' '}
            <span>Hub71</span>
          </li>
          <RenderIf condition={!identity.isEmptyString(date)}>
            <li>
              <span>{date}</span>
            </li>
          </RenderIf>
          <RenderIf condition={identity.isPositiveNumber(readTime)}>
            <li>
              {readTime}
              {' '}
              <span>
                {readTime > 1 ? 'minutes' : 'minute'}
                {' '}
                read
              </span>
            </li>
          </RenderIf>
        </ul>
      </div>
      <RenderIf condition={!identity.isEmptyString(banner)}>
        <div className="article-featured-image">
          <img src={banner} alt="" />
        </div>
      </RenderIf>

      <RenderIf condition={!identity.isEmptyString(subTitle)}>
        <h3 className="text text-md text-700 article-title">{subTitle}</h3>
      </RenderIf>

      <RenderIf condition={!identity.isEmptyString(content)}>
        {/*  eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </RenderIf>

    </div>
  )
}

export default ArticleDetails
