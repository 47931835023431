import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Button, Form } from 'antd';
import { RcFile } from 'antd/lib/upload';

import {
  identity,
  DRAWERS,
  isEitherAdmin,
  isTechnicalLicense,
} from 'utils';
import {
  TitleWithActions,
  Input,
  Breadcrumb,
  // EditProfileImage,
} from 'components/components';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { EdiTechnicalLicenseProps } from 'types/technicalLicenses';
import routes from 'routes';
import { composeEditTechnicalLicenseUrl } from 'routes/routeComposers';
import { getTechnicalLicenseFile, useEditTechnicalLicenseHook } from './utils';
import { ViewUploadButton } from './components';

import './EditTechnicalLicense.scss';

const EditTechnicalLicense = (props: EdiTechnicalLicenseProps) => {
  const {
    selectedTechnicalLicense,
    userTechnicalLicenseId,
    userType,
  } = props;
  const {
    getTechnicalLicenseById,
    updateTechnicalLicenseById,
    setDrawerBody,
    setIsDrawerOpen,
  } = props.actions;
  const [form] = Form.useForm();

  const params: { id?: string } = useParams();
  const { id } = params;
  const technicalLicenseId = useMemo(
    () => (identity.isTruthyNumber(userTechnicalLicenseId) ? userTechnicalLicenseId : parseInt(id!, 10)),
    [id],
  );

  const { state, setAll, setField } = useEditTechnicalLicenseHook();

  const {
    currentADGMLicense,
    aDGMApprovedStatus,
    // incentiveAgreementApprovedStatus,
    // currentIncentiveAgreement,
    acceptanceLetter,
    acceptanceLetterApprovedStatus,
    status,
    // imageLink,
    about,
    website,
  } = state;

  const cleanAboutText = about.replace(/<\/?[^>]+(>|$)/g, '')

  // const waitingForApprovalIA = useMemo(() => [
  //   identity.isZero(incentiveAgreementApprovedStatus),
  //   identity.isTruthyString(currentIncentiveAgreement?.file),
  // ].every(Boolean), [currentIncentiveAgreement, incentiveAgreementApprovedStatus]);

  const waitingForApprovalADGM = useMemo(() => [
    identity.isZero(aDGMApprovedStatus),
    identity.isTruthyString(currentADGMLicense?.aDGMLicenseUrl),
  ].every(Boolean), [aDGMApprovedStatus, currentADGMLicense]);

  const waitingForApprovalAL = useMemo(() => [
    identity.isZero(status.id),
    identity.isEmptyString(acceptanceLetter),
  ].some(Boolean), [status, acceptanceLetter]);

  useEffect(() => {
    getTechnicalLicenseById({ id: technicalLicenseId, owner: 'technical-license', isUpdate: false });
  }, [id]);

  useEffect(() => {
    setAll({ technicalLicense: selectedTechnicalLicense });
  }, [selectedTechnicalLicense]);

  useEffect(() => {
    form.setFieldsValue(state);
  }, [state]);

  const handleSubmit = () => {
    updateTechnicalLicenseById({ id: technicalLicenseId, technicalLicense: state });
  }

  // const beforeLogoUpload = (file: RcFile, field: 'imageLink') => {
  //   setField({ field, value: file as Blob });
  //   return false;
  // }

  const beforeAcceptanceLetterUpload = (file: RcFile) => {
    setField({ field: 'acceptanceLetter', value: file as Blob });
    return false;
  }

  const handleOpenDrawer = (drawerBody: string) => {
    setIsDrawerOpen({ isDrawerOpen: true });
    setDrawerBody({ drawerBody });
  }

  return (
    <div className="edit-technicallicense__container content-container">
      <Breadcrumb navigateTo={isTechnicalLicense(userType) ? routes.companyProfileUrl : composeEditTechnicalLicenseUrl(technicalLicenseId)} routeName="Technical license Details" />
      <TitleWithActions title={selectedTechnicalLicense?.name} />
      <Form
        onFinish={handleSubmit}
        onFinishFailed={scrollToFirstError}
        validateTrigger="onSubmit"
        form={form}
      >
        <div className="edit-technicallicense__fields">
          {/* <div className="edit-technicallicense__section">
            <EditProfileImage imageLink={imageLink} beforeLogoUpload={beforeLogoUpload} />
          </div> */}
          <div className="edit-technicallicense__section">
            <Input.Heading title="Technical license Details" />
            <Input label="About">
              <Input.InputField.TextArea
                rows={3}
                value={cleanAboutText}
                required
                onChange={(e) => setField({ field: 'about', value: e.target.value })}
              />
            </Input>
            <Input label="Website">
              <Input.InputField
                type="text"
                value={website}
                onChange={(e) => setField({ field: 'website', value: e.target.value })}
              />
            </Input>
          </div>
          <div className="edit-technicallicense__section">
            <br />
            <Input.Heading title="Documents" />
            <Input label="Acceptance Letter">
              {waitingForApprovalAL || (isEitherAdmin(userType) && acceptanceLetterApprovedStatus !== 1)
                ? (
                  <p className="message">
                    {waitingForApprovalAL
                      ? 'Waiting for Acceptance Letter to be approved.'
                      : 'Waiting for technical license to upload acceptance letter.'}
                  </p>
                )
                : (
                  <Input.DraggerUpload
                    defaultFileList={identity.isTruthyString(acceptanceLetter) // @ts-ignore: Unreachable code error
                      ? [...getTechnicalLicenseFile(acceptanceLetter!)] : []}
                    previewType="image"
                    beforeUpload={beforeAcceptanceLetterUpload}
                    accept=".pdf"
                    isRoundableButton
                    name={identity.isTruthyString(acceptanceLetter) ? 'acceptanceLetter' : ''}
                    allowChange={identity.isZero(acceptanceLetterApprovedStatus)
                        && identity.isTruthyString(acceptanceLetter)}
                  />
                )}
            </Input>
            <Input label="ADGM License">
              {waitingForApprovalADGM
                ? <p className="message">Waiting for ADGM license to be approved.</p>
                : (
                  <ViewUploadButton
                    userType={userType}
                    name="ADGM License"
                    onClick={() => handleOpenDrawer(DRAWERS.tLHAdgmLicenseDetails)}
                    condition={aDGMApprovedStatus === 1 && identity.isTruthyString(currentADGMLicense?.aDGMLicenseUrl)}
                    status={aDGMApprovedStatus ?? undefined}
                  />
                )}
            </Input>
            {/* <Input label="Incentive Agreement">
              {waitingForApprovalIA
                ? <p className="message">Waiting for Incentive Agreement to be approved.</p>
                : (
                  <ViewUploadButton
                    userType={userType}
                    name="Incentive Agreement"
                    onClick={() => handleOpenDrawer(DRAWERS.tLHIncentiveAgreementDetails)}
                    condition={incentiveAgreementApprovedStatus === 1
                      && identity.isTruthyString(currentIncentiveAgreement?.file)}
                    status={incentiveAgreementApprovedStatus}
                  />
                )}
            </Input> */}
          </div>
          <Button type="primary" htmlType="submit" className="primary-button">
            Update Technical license
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default EditTechnicalLicense;
