import React from 'react'
import { Route } from 'react-router-dom';

import { TechnicalHolderRouteProps } from 'types/routes/PrivateRouteProps';

const TechnicalHolderRoute = (props: TechnicalHolderRouteProps) => {
  const {
    component,
    exact,
    path,
  } = props;

  return <Route path={path} exact={exact} component={component} />
}

export default TechnicalHolderRoute;
