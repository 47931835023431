import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectIsLoading, selectMyProfileData, selectUserType } from 'redux/selectors/auth';
import { getMyProfileData } from 'redux/actions/auth';

const mapStateToProps = (state: StateType) => ({
  user: selectMyProfileData(state),
  isLoading: selectIsLoading(state),
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ getMyProfileData }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
