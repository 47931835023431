import React, { useEffect, useMemo } from 'react';

import { RequestsOverviewProps } from 'types/requests/requestsOverview';
import {
  isStartup,
  useTableHook,
  getOffset,
  identity,
  isPartner,
  isAngelInvestor,
  isAdmin,
  isSuperAdmin,
} from 'utils';
import { REQUEST_STATUS_TO_REQUEST_STATUS_KEY_MAP } from 'packages/service_request_repository';
import { RenderIf, TitleWithActions, CustomPagination } from '../components';
import { RequestOverviewTabs, ServiceRequestsByCategory, UnsentServiceRequests } from './components';
import { isUnsent } from './utils';
import { FILTERS_MAP } from '../components/SearchBar/utils/constants';
import REQUEST_STATUS from '../../utils/constants/requestStatusTypes'
import Tabs from './components/Tabs';

const RequestsOverview = (props: RequestsOverviewProps) => {
  const {
    requests,
    userType,
    currentTab,
    currentUserTab,
    count,
    filters,
    filtersActive,
  } = props;
  const {
    getRequests,
    setCurrentTab,
    getUnsentRequests,
    setCurrentUserTypeTab,
  } = props.actions;
  const { setLimit, setCurrentPage, state } = useTableHook();
  const { limit, currentPage } = state;

  const requestLoad = (offset: number) => {
    const currentFilters = { ...filters };
    if (['startup'].includes(currentUserTab)) {
      currentFilters.type = 'startup';
    }
    if (['angel-investor'].includes(currentUserTab)) {
      currentFilters.type = 'angel_investor';
    }
    getRequests({ data: { limit, offset, ...currentFilters } });
  }

  useEffect(() => {
    getUnsentRequests();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  useEffect(() => {
    const offset = getOffset(limit, currentPage);
    requestLoad(offset);
  }, [currentTab, currentUserTab, limit, currentPage, filters]);

  useEffect(() => {
    if (isUnsent(currentTab) && !isAngelInvestor(userType) && !isStartup(userType)) {
      setCurrentTab({ currentTab: 'all' });
    }
    setCurrentPage(1);
  }, [currentTab, userType]);

  const unsentCount = useMemo(() => (
    identity.isFullArray(props.unsentRequests) ? props.unsentRequests.length : 0
  ), [props.unsentRequests]);

  const subtitle = useMemo(() => {
    switch (currentTab) {
      case REQUEST_STATUS.new: {
        return 'These are the requests pending approval with the service provider (with price modification if any).';
      }
      case REQUEST_STATUS.quoted: {
        return 'These are the requests users need to approve.';
      }
      case REQUEST_STATUS.pendingHUB71: {
        return 'These are the requests that need to be approved by Hub71';
      }
      case REQUEST_STATUS.inProgress: {
        return 'These are the requests that are being worked on.';
      }
      case REQUEST_STATUS.pendingQuotation: {
        return 'These are the requests that need to be approved by service provider';
      }
      case REQUEST_STATUS.delivered: {
        return 'These are all the requests that have been delivered';
      }
      case REQUEST_STATUS.rejected: {
        return 'These are all the requests that have been rejected';
      }
      default: {
        return 'View and manage all your requests.';
      }
    }
  }, [currentTab]);

  return (
    <div className="requests-overview content-container">
      <TitleWithActions
        size="l"
        title="Requests"
        subtitle={subtitle}
        showSearchBar
        showFilterButton
        filtersActive={filtersActive}
        showExportButton={!isPartner(userType)}
        filterButtonType="dropdown"
        filterDropdownElements={FILTERS_MAP.requests}
        exportDataModel="servicerequest"
        showFilterByUserType={false}
        setCurrentUserTypeTab={setCurrentUserTypeTab}
      />
      <RenderIf condition={isSuperAdmin(userType) || isAdmin(userType)}>
        <Tabs setCurrentPage={setCurrentPage} />
      </RenderIf>
      <RequestOverviewTabs unsentCount={unsentCount} />
      {isUnsent(currentTab) && (isStartup(userType) || isAngelInvestor(userType))
        ? <UnsentServiceRequests />
        : <ServiceRequestsByCategory filteredRequests={requests} />}
      <RenderIf condition={!isUnsent(currentTab)}>
        <CustomPagination
          setLimit={setLimit}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          limit={limit}
          total={count[REQUEST_STATUS_TO_REQUEST_STATUS_KEY_MAP[currentTab]]}
        />
      </RenderIf>
    </div>
  )
}

export default RequestsOverview;
