import React from 'react';
import classnames from 'classnames';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';

import { identity, isTechnicalLicense } from 'utils';
import { ImageIcon } from 'assets/icons';
import { ProfileOverviewProps } from 'types/componentTypes/ProfileOverviewProps';
import { RenderIf } from 'components/components';

import './ProfileOverview.scss';

const ProfileOverview = (props: ProfileOverviewProps) => {
  const {
    imageUrl,
    heading,
    coverImage,
    // subheadingIsLocation,
    // subheading,
    rightContent,
    mainSubheading,
    socialMediaLinks,
    userType,
  } = props;
  const isRealImageLink = imageUrl?.includes('/media')!;
  return (
    <div className="profile-overview">
      <div className="profile-overview__left-content-container">
        <RenderIf condition={!isTechnicalLicense(userType)}>
          <div className="profile-overview__image-container">
            {identity.isTruthyString(imageUrl) && isRealImageLink
              ? <img className={classnames('profile-overview__image', { 'cover-image': coverImage })} src={imageUrl} alt={heading} />
              : (
                <div className="profile-overview__no-image-container">
                  <ImageIcon />
                </div>
              )}
          </div>
        </RenderIf>

        <div className="profile-overview__left-content-item">
          <h3 className="profile-overview__heading">{heading}</h3>
          <RenderIf condition={identity.isTruthyString(mainSubheading)}>
            <div className="profile-overview__subheading-container">
              <span className="profile-overview__subheading">
                {mainSubheading}
              </span>
            </div>
          </RenderIf>
          <RenderIf condition={!isTechnicalLicense(userType)}>
            <RenderIf condition={identity.isObjWithChildren(socialMediaLinks)}>
              <div className="profile-overview__right-content-item">
                <div className="profile-overview__social-media">
                  <div className="profile-overview__social-media-item">
                    {identity.isTruthyString(socialMediaLinks?.facebook)
                      ? <a aria-label="facebook" rel="noopener noreferrer" target="_blank" href={socialMediaLinks?.facebook!}><FaFacebookF /></a>
                      : <FaFacebookF />}
                  </div>
                  <div className="profile-overview__social-media-item">
                    {identity.isTruthyString(socialMediaLinks?.instagram)
                      ? <a aria-label="instagram" rel="noopener noreferrer" target="_blank" href={socialMediaLinks?.instagram!}><AiOutlineInstagram /></a>
                      : <AiOutlineInstagram />}
                  </div>
                  <div className="profile-overview__social-media-item">
                    {identity.isTruthyString(socialMediaLinks?.twitter)
                      ? <a aria-label="twitter" rel="noopener noreferrer" target="_blank" href={socialMediaLinks?.twitter!}><AiOutlineTwitter /></a>
                      : <AiOutlineTwitter />}
                  </div>
                  <div className="profile-overview__social-media-item">
                    {identity.isTruthyString(socialMediaLinks?.linkedIn)
                      ? <a aria-label="linkedin" rel="noopener noreferrer" target="_blank" href={socialMediaLinks?.linkedIn!}><FaLinkedinIn /></a>
                      : <FaLinkedinIn />}
                  </div>
                </div>
              </div>
            </RenderIf>
          </RenderIf>

          {/* <div className="profile-overview__subheading-container">
            <RenderIf condition={subheadingIsLocation!}><LocationIcon /></RenderIf>
            <span className="profile-overview__subheading">{identity.isTruthyString(props.subheading) ? subheading : ''}</span>
          </div> */}
        </div>
      </div>
      <div className="profile-overview__right-content">
        <div className="profile-overview__right-content-container">
          {rightContent.map(({ label, value }) => (
            <div className="profile-overview__right-content-item" key={label}>
              <span className="profile-overview__right-content-item--label">{label}</span>
              <span className="profile-overview__right-content-item--value">{identity.isTruthyString(value?.toString()) ? value : 'Not specified'}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

ProfileOverview.defaultProps = {
  coverImage: false,
  subheadingIsLocation: false,
  socialMediaLinks: {},
}

export default ProfileOverview;
