import { PayloadAction } from '@reduxjs/toolkit';

import { EditTechnicalLicenseMemberType } from 'types/people/editMemberProfile';
import { SetFieldType } from 'types/reduxTypes/ActionTypes/PeopleTypes';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { defaultTechnicalLicenseMember } from 'redux/utils/defaultStates/MemberDefaultState';
import { types } from './actions';

type EditTechnicalLicenseMemberState = {
  member: EditTechnicalLicenseMemberType,
  skillsShowError: boolean,
  hobbiesShowError: boolean,
  jobFunctionsShowError: boolean,
}

const initialState: EditTechnicalLicenseMemberState = {
  member: {
    ...defaultTechnicalLicenseMember,
    skills: '',
    hobbies: '',
    jobFunctions: '',
    numberOfKids: 0,
    gender: 0,
    hasEmiratesId: 0,
    nationalityId: 0,
    passport: '',
    visa: '',
  },
  skillsShowError: false,
  hobbiesShowError: false,
  jobFunctionsShowError: false,
};

const handleSetAll = (
  state: EditTechnicalLicenseMemberState,
  payload: DataPayload<EditTechnicalLicenseMemberType>,
) => ({
  ...state, member: payload.data,
});

const handleSetField = (state: EditTechnicalLicenseMemberState, payload: SetFieldType) => ({
  ...state, member: { ...state.member, [payload.field]: payload.value },
});

const handleSetLogo = (state: EditTechnicalLicenseMemberState, payload: DataPayload<string | Blob>) => ({
  ...state, member: { ...state.member, emiratesId: payload.data },
});

const handleSetShowError = (state: EditTechnicalLicenseMemberState, payload: SetFieldType) => ({
  ...state, [`${payload.field}ShowError`]: payload.value,
});

const handleSetPassport = (state: EditTechnicalLicenseMemberState, payload: DataPayload<string | Blob>) => ({
  ...state, member: { ...state.member, passport: payload.data },
});

const handleSetVisa = (state: EditTechnicalLicenseMemberState, payload: DataPayload<string | Blob>) => ({
  ...state, member: { ...state.member, visa: payload.data },
});

const reducer = (
  state: EditTechnicalLicenseMemberState,
  action: PayloadAction<DataPayload<EditTechnicalLicenseMemberType | string | Blob> | SetFieldType>,
): EditTechnicalLicenseMemberState => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_TLH_ALL: {
      return handleSetAll(state, <DataPayload<EditTechnicalLicenseMemberType>>payload);
    }
    case types.SET_TLH_FIELD: {
      return handleSetField(state, <SetFieldType>payload);
    }
    case types.SET_TLH_EMIRATES_ID_PEOPLE: {
      return handleSetLogo(state, <DataPayload<string | Blob>>payload);
    }
    case types.SHOW_TLH_ERROR: {
      return handleSetShowError(state, <SetFieldType>payload);
    }
    case types.SET_TLH_VISA: {
      return handleSetVisa(state, <DataPayload<string | Blob>>payload);
    }
    case types.SET_TLH_PASSPORT: {
      return handleSetPassport(state, <DataPayload<string | Blob>>payload);
    }
    default:
      return state;
  }
}

export { reducer, initialState };
