import { connect } from 'react-redux';

import {
  selectUser,
  selectUserTechnicalLicense,
} from 'redux/selectors/auth';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  user: selectUser(state),
  technicalLicense: selectUserTechnicalLicense(state),
});

export default connect(mapStateToProps);
