import {
  CreateTechnicalLicensePayload,
  StageType,
  InvestmentStageType,
  TechnicalLicenseQuery,
  TLHCreateADGMLicensePayload,
  IncentiveAgreementType,
} from 'packages/technical_holder_repository';
import {
  SetTechnicalLicensesPayload,
  GetProviderServicesWithTechnicalLicensePayload,
  SetTechnicalLicenseCountPayload,
  SetTechnicalLicenseById,
  SetTechnicalLicenseForReviewPayload,
  UpdateTechnicalLicensePayload, GetTechnicalLicenseByIdPayload,
} from 'types/reduxTypes/ActionTypes/TechnicalLicensesTypes'
import {
  DataPayload,
  GetAllQueryPayload,
  IdPayload,
  IsLoadingPayload,
} from 'types/reduxTypes/ActionTypes';
import { ChangeRequestStatusPayload } from 'types/reduxTypes/ActionTypes/RequestsTypes';
import { GetTeamMembersPayload, SetTeamMembersPayload } from 'types/reduxTypes/ActionTypes/TeamMemberTypes';
import { TLHADGMLicenseType } from 'packages/technical_holder_repository/types/adgm_license';
import { PeopleFilters } from 'types/people';
import {
  CreateIncentiveAgreementPayload,
  TechnicalLicenseFiltersType,
  UpdateIncentiveAgreementPayload,
} from 'types/technicalLicenses';
import { ParamsPayload } from 'packages/http_client';
import { ServiceProviderType } from 'types/serviceProviders';
import { GetAmountSumsResponse } from 'packages/wallet_repository';

export const types = {
  CREATE_TECHNICAL_LICENSE_ADGM_LICENSE: 'CREATE_TECHNICAL_LICENSE_ADGM_LICENSE',
  GET_INCENTIVE_AGREEMENT_BY_ID: 'GET_INCENTIVE_AGREEMENT_BY_ID',
  SET_TECHNICAL_LICENSE_INCENTIVE_AGREEMENT_BY_ID: 'SET_TECHNICAL_LICENSE_INCENTIVE_AGREEMENT_BY_ID',
  GET_TECHNICAL_LICENSES: 'GET_TECHNICAL_LICENSES',
  SET_TECHNICAL_LICENSES: 'SET_TECHNICAL_LICENSES',
  SET_TECHNICAL_LICENSES_COUNT: 'SET_TECHNICAL_LICENSES_COUNT',
  GET_TECHNICAL_LICENSE_BY_ID: 'GET_TECHNICAL_LICENSE_BY_ID',
  SET_TECHNICAL_LICENSE_BY_ID: 'SET_TECHNICAL_LICENSE_BY_ID',
  GET_ADGM_LICENSE_BY_TECHNICAL_LICENSE_ID: 'GET_ADGM_LICENSE_BY_TECHNICAL_LICENSE_ID',
  SET_ADGM_LICENSE_BY_TECHNICAL_LICENSE_ID: 'SET_ADGM_LICENSE_BY_TECHNICAL_LICENSE_ID',
  SET_TECHNICAL_LICENSE_REQUESTS: 'SET_TECHNICAL_LICENSE_REQUESTS',
  SET_REQUEST_STATUS_CHANGE: 'SET_REQUEST_STATUS_CHANGE',
  CREATE_TECHNICAL_LICENSE: 'CREATE_TECHNICAL_LICENSE',
  GET_TECHNICAL_LICENSE_MEMBERS: 'GET_TECHNICAL_LICENSE_MEMBERS',
  SET_TECHNICAL_LICENSE_MEMBERS: 'SET_TECHNICAL_LICENSE_MEMBERS',
  UPDATE_TECHNICAL_LICENSE_BY_ID: 'UPDATE_TECHNICAL_LICENSE_BY_ID',
  UPDATE_ADGM_LICENSE_BY_TECHNICAL_LICENSE_ID: 'UPDATE_ADGM_LICENSE_BY_TECHNICAL_LICENSE_ID',
  SET_TECHNICAL_LICENSE_SERVICE_PROVIDER_BY_ID: 'SET_TECHNICAL_LICENSE_SERVICE_PROVIDER_BY_ID',
  GET_PROVIDER_SERVICES_WITH_TECHNICAL_LICENSE: 'GET_PROVIDER_SERVICES_WITH_TECHNICAL_LICENSE',
  GET_TECHNICAL_LICENSES_BY_OWNER_ID: 'GET_TECHNICAL_LICENSES_BY_OWNER_ID',
  GET_SECTORS: 'GET_SECTORS',
  GET_TECHNICAL_LICENSE_STAGES: 'GET_TECHNICAL_LICENSE_STAGES',
  GET_INVESTMENT_STAGES: 'GET_INVESTMENT_STAGES',
  SET_SECTOR_OPTIONS: 'SET_SECTOR_OPTIONS',
  SET_TECHNICAL_LICENSE_STAGES_OPTIONS: 'SET_TECHNICAL_LICENSE_STAGES_OPTIONS',
  SET_INVESTMENT_STAGE_OPTIONS: 'SET_INVESTMENT_STAGE_OPTIONS',
  SET_TECHNICAL_LICENSES_IS_LOADING: 'SET_TECHNICAL_LICENSES_IS_LOADING',
  GET_TECHNICAL_LICENSES_COUNT: 'GET_TECHNICAL_LICENSES_COUNT',
  GET_TECHNICAL_LICENSE_TEAM_MEMBERS_COUNT: 'GET_TECHNICAL_LICENSE_MEMBERS_COUNT',
  SET_TECHNICAL_LICENSE_TEAM_MEMBERS_COUNT: 'SET_TECHNICAL_LICENSE_MEMBERS_COUNT',
  SET_TECHNICAL_LICENSE_ID: 'SET_TECHNICAL_LICENSE_ID',
  CHANGE_TECHNICAL_LICENSE_DOCUMENT_STATUS: 'CHANGE_TECHNICAL_LICENSE_DOCUMENT_STATUS',
  SET_TECHNICAL_LICENSE_FOR_REVIEW: 'SET_TECHNICAL_LICENSE_FOR_REVIEW',
  SET_TECHNICAL_LICENSE_FILTERS: 'SET_TECHNICAL_LICENSE_FILTERS',
  RESET_TECHNICAL_LICENSES: 'RESET_TECHNICAL_LICENSES',
  GET_TECHNICAL_LICENSE_SERVICE_PROVIDERS: 'GET_TECHNICAL_LICENSE_SERVICE_PROVIDERS',
  SET_TECHNICAL_LICENSE_SERVICE_PROVIDERS: 'SET_TECHNICAL_LICENSE_SERVICE_PROVIDERS',
  SET_SUMS_FOR_SERVICE_PROVIDERS: 'SET_SUMS_FOR_SERVICE_PROVIDERS',
  CREATE_INCENTIVE_AGREEMENT: 'CREATE_INCENTIVE_AGREEMENT',
  UPDATE_INCENTIVE_AGREEMENT: 'UPDATE_INCENTIVE_AGREEMENT',
};

export const setSumsForServiceProviders = (payload: DataPayload<GetAmountSumsResponse[]>) => ({
  type: types.SET_SUMS_FOR_SERVICE_PROVIDERS,
  payload,
});

export const setTechnicalLicenseServiceProviders = (payload: DataPayload<ServiceProviderType[]>) => ({
  type: types.SET_TECHNICAL_LICENSE_SERVICE_PROVIDERS,
  payload,
});

export const getTechnicalLicenseServiceProviders = (payload: GetAllQueryPayload<ParamsPayload>) => ({
  type: types.GET_TECHNICAL_LICENSE_SERVICE_PROVIDERS,
  payload,
});

export const resetTechnicalLicenses = (payload: object = {}) => ({
  type: types.RESET_TECHNICAL_LICENSES,
  payload,
});

export const updateADGMLicenseByTechnicalLicenseId = (payload: DataPayload<TLHADGMLicenseType>) => ({
  type: types.UPDATE_ADGM_LICENSE_BY_TECHNICAL_LICENSE_ID,
  payload,
});

export const getADGMLicenseByTechnicalLicenseId = (payload: IdPayload) => ({
  type: types.GET_ADGM_LICENSE_BY_TECHNICAL_LICENSE_ID,
  payload,
});

export const setADGMLicenseByTechnicalLicenseId = (payload: DataPayload<TLHADGMLicenseType>) => ({
  type: types.SET_ADGM_LICENSE_BY_TECHNICAL_LICENSE_ID,
  payload,
});

export const setTeamMembersCount = (payload: DataPayload<number>) => ({
  type: types.SET_TECHNICAL_LICENSE_TEAM_MEMBERS_COUNT,
  payload,
});
export const getTeamMembersCount = (payload: PeopleFilters) => ({
  type: types.GET_TECHNICAL_LICENSE_TEAM_MEMBERS_COUNT,
  payload,
});

export const getTechnicalLicenseCount = (payload: object = {}) => ({
  type: types.GET_TECHNICAL_LICENSES_COUNT,
  payload,
});

export const getTechnicalLicenses = (payload: TechnicalLicenseQuery = {}) => ({
  type: types.GET_TECHNICAL_LICENSES,
  payload,
});

export const setTechnicalLicenses = (payload: SetTechnicalLicensesPayload) => ({
  type: types.SET_TECHNICAL_LICENSES,
  payload,
});

export const setTechnicalLicensesCount = (payload: SetTechnicalLicenseCountPayload) => ({
  type: types.SET_TECHNICAL_LICENSES_COUNT,
  payload,
});

export const getTechnicalLicenseById = (payload: GetTechnicalLicenseByIdPayload) => ({
  type: types.GET_TECHNICAL_LICENSE_BY_ID,
  payload,
});

export const setTechnicalLicenseById = (payload: SetTechnicalLicenseById) => ({
  type: types.SET_TECHNICAL_LICENSE_BY_ID,
  payload,
});

export const setRequestStatusChange = (payload: ChangeRequestStatusPayload) => ({
  type: types.SET_REQUEST_STATUS_CHANGE,
  payload,
});

export const createTechnicalLicense = (payload: DataPayload<CreateTechnicalLicensePayload>) => ({
  type: types.CREATE_TECHNICAL_LICENSE,
  payload,
});

export const getTechnicalLicenseMembers = (payload: GetTeamMembersPayload) => ({
  type: types.GET_TECHNICAL_LICENSE_MEMBERS,
  payload,
});

export const setTechnicalLicenseMembers = (payload: SetTeamMembersPayload) => ({
  type: types.SET_TECHNICAL_LICENSE_MEMBERS,
  payload,
});

export const updateTechnicalLicenseById = (payload: UpdateTechnicalLicensePayload) => ({
  type: types.UPDATE_TECHNICAL_LICENSE_BY_ID,
  payload,
});

export const getProviderServicesWithTechnicalLicense = (payload: GetProviderServicesWithTechnicalLicensePayload) => ({
  type: types.GET_PROVIDER_SERVICES_WITH_TECHNICAL_LICENSE,
  payload,
});

export const getTechnicalLicenseSectors = (payload: object = {}) => ({
  type: types.GET_SECTORS,
  payload,
});

export const getTechnicalLicenseStages = (payload: object = {}) => ({
  type: types.GET_TECHNICAL_LICENSE_STAGES,
  payload,
});

export const getTechnicalLicenseInvestmentStages = (payload: object = {}) => ({
  type: types.GET_INVESTMENT_STAGES,
  payload,
});

export const setStageOptions = (payload: DataPayload<StageType[]>) => ({
  type: types.SET_TECHNICAL_LICENSE_STAGES_OPTIONS,
  payload,
});

export const setInvestmentStageOptions = (payload: DataPayload<InvestmentStageType[]>) => ({
  type: types.SET_INVESTMENT_STAGE_OPTIONS,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_TECHNICAL_LICENSES_IS_LOADING,
  payload,
});

export const setTechnicalLicenseId = (payload: DataPayload<number>) => ({
  type: types.SET_TECHNICAL_LICENSE_ID,
  payload,
});

export const setTechnicalLicenseForReview = (payload: SetTechnicalLicenseForReviewPayload) => ({
  type: types.SET_TECHNICAL_LICENSE_FOR_REVIEW,
  payload,
});

export const createTechnicalLicenseADGMLicense = (payload: TLHCreateADGMLicensePayload) => ({
  type: types.CREATE_TECHNICAL_LICENSE_ADGM_LICENSE,
  payload,
});

export const getIncentiveAgreementById = (payload: object = {}) => ({
  type: types.GET_INCENTIVE_AGREEMENT_BY_ID,
  payload,
});

export const setIncentiveAgreementById = (payload: DataPayload<IncentiveAgreementType>) => ({
  type: types.SET_TECHNICAL_LICENSE_INCENTIVE_AGREEMENT_BY_ID,
  payload,
});

export const setTechnicalLicenseFilters = (payload: TechnicalLicenseFiltersType) => ({
  type: types.SET_TECHNICAL_LICENSE_FILTERS,
  payload,
});

export const createIncentiveAgreement = (payload: CreateIncentiveAgreementPayload) => ({
  type: types.CREATE_INCENTIVE_AGREEMENT,
  payload,
});

export const updateIncentiveAgreement = (payload: UpdateIncentiveAgreementPayload) => ({
  type: types.UPDATE_INCENTIVE_AGREEMENT,
  payload,
});
