import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getTechnicalLicenseById, getTechnicalLicenseMembers, getTechnicalLicenseServiceProviders } from 'redux/actions/technicalLicense';
import { StateType } from 'types';
import {
  selectTechnicalLicenseName,
  selectAboutTechnicalLicense,
  selectStatCards,
  selectTechnicalLicenseTeamMembers,
  selectTechnicalLicenseDetails,
  selectTechnicalLicenseDetailsRequests,
  selectIsLoading,
  selectPartnersStatCards,
  selectTechnicalLicenseServiceProviders,
} from 'redux/selectors/technicalLicenses';

import { getRequestsByOwnerId } from 'redux/actions/request';
import { selectUserTechnicalLicenseId, selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  technicalLicenseName: selectTechnicalLicenseName(state),
  aboutTechnicalLicense: selectAboutTechnicalLicense(state),
  statCards: selectStatCards(state),
  partnersStatCards: selectPartnersStatCards(state),
  teamMembers: selectTechnicalLicenseTeamMembers(state),
  info: selectTechnicalLicenseDetails(state),
  requests: selectTechnicalLicenseDetailsRequests(state),
  userType: selectUserType(state),
  isLoading: selectIsLoading(state),
  userTechnicalLicenseId: selectUserTechnicalLicenseId(state),
  technicalLicenseServiceProviders: selectTechnicalLicenseServiceProviders(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getTechnicalLicenseById,
    getTechnicalLicenseMembers,
    getRequestsByOwnerId,
    getTechnicalLicenseServiceProviders,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
