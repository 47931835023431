import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import {
  selectAdgmLicenseByStartupId,
  selectSectorOptions,
  selectStageOptions,
  selectIsLoading,
} from 'redux/selectors/startups';
import {
  selectIsAuthenticated,
  selectStartupStatus,
  selectTechnicalHolderStatus,
  selectUserStartup,
  selectUserStartupId,
  selectUserType,
} from 'redux/selectors/auth';
import { StateType } from 'types';
import {
  getStartupById,
  updateStartupById,
  getStartupSectors,
  getStartupStages,
  getADGMLicenseByStartupId,
  updateADGMLicenseByStartupId,
  createStartupADGMLicense,
} from 'redux/actions/startups';

const mapStateToProps = (state: StateType) => ({
  details: selectUserStartup(state),
  startupId: selectUserStartupId(state),
  sectorOptions: selectSectorOptions(state),
  stageOptions: selectStageOptions(state),
  adgmLicenseByStartupId: selectAdgmLicenseByStartupId(state),
  isLoading: selectIsLoading(state),
  userType: selectUserType(state),
  isAuthenticated: selectIsAuthenticated(state),
  startupStatus: selectStartupStatus(state),
  technicalHolderStatus: selectTechnicalHolderStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStartupById,
    getStartupSectors,
    getStartupStages,
    updateStartupById,
    createStartupADGMLicense,
    getADGMLicenseByStartupId,
    updateADGMLicenseByStartupId,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
