// @ts-nocheck

import { createSelector } from 'reselect';

import { StateType } from 'types';
import { TechnicalLicensesStateType } from 'types/reduxTypes/StateTypes';
import { TechnicalLicenseType } from 'types//technicalLicenses';
import { RequestType } from 'types/requests';
import routes from 'routes';
import { isProduction } from 'utils';

const selectTechnicalLicensesState = (state: StateType) => state.technicalLicenseReducer;

export const selectIncentiveAgreement = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.incentiveAgreement,
)

export const selectTechnicalLicenses = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.angelInvestors,
);

export const selectTechnicalLicenseCount = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.angelInvestorCount,
);

export const selectTechnicalLicenseDetails = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.details.data,
);

export const selectTechnicalLicenseRequests = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.details.requests,
)

export const selectTechnicalLicenseDetailsRequests = createSelector(
  selectTechnicalLicenseRequests,
  (state: RequestType[]) => state.slice(0, 3),
);

export const selectTechnicalLicenseId = createSelector(
  selectTechnicalLicenseDetails,
  (state: TechnicalLicenseType) => state.id,
);

export const selectAboutTechnicalLicense = createSelector(
  selectTechnicalLicenseDetails,
  (state: TechnicalLicenseType) => state.about,
);

export const selectTechnicalLicenseName = createSelector(
  selectTechnicalLicenseDetails,
  (state: TechnicalLicenseType) => state.name,
);

export const selectStatCards = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => {
    const stats = [
      { title: 'service providers', count: state.details.serviceProvidersCount || 0, path: routes.servicesAndSpsOverviewUrl },
    ];

    if (!isProduction) {
      stats.push({ title: 'upcoming events', count: state.details.data.upcomingEventsCount || 0, path: routes.eventsUrl });
    }

    return stats;
  },
);

export const selectPartnersStatCards = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => {
    const stats = [
      { title: 'founders', count: state.details.teamMembers.filter((member) => member.founder)?.length, path: '' },
    ];

    if (!isProduction) {
      stats.push({ title: 'upcoming events', count: state.details.data?.upcomingEventsCount || 0, path: routes.eventsUrl });
    }

    return stats;
  },
);

export const selectIncentiveAgreementStatus = createSelector(
  selectTechnicalLicenseDetails,
  (state: TechnicalLicenseType) => state.incentiveAgreementApprovedStatus,
);

export const selectTechnicalLicenseTeamMembers = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.details.teamMembers.filter(
    (member: any) => member.company === state.details.data.name,
  ),
);

export const selectTechnicalLicenseServiceProviders = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.details.serviceProviders,
);

export const selectTechnicalLicenseLocations = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.locations,
);

export const selectStageOptions = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.stageOptions,
);

export const selectInvestmentStageOptions = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.investmentStageOptions,
);

export const selectIsLoading = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.isLoading,
);

export const selectTechnicalLicenseTeamMembersCount = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.details.teamMembersCount,
);

export const selectAdgmLicenseByTechnicalLicenseId = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.adgmLicenseByTechnicalLicenseId,
);

export const selectSelectedTechnicalLicenseId = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.angelInvestorId!,
);

export const selectTechnicalLicenseForReview = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.selectedTechnicalLicenseForReview,
);

export const selectTechnicalLicenseFilters = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.filters,
);

export const selectTechnicalLicenseFiltersActive = createSelector(
  selectTechnicalLicensesState,
  (state: TechnicalLicensesStateType) => state.filtersActive,
);
