import React, { useEffect, useMemo } from 'react';
import { Button } from 'antd';

import { CloseIcon } from 'assets/icons';
import {
  identity,
  isAngelInvestor,
  isEitherAdmin,
  isPartner,
  isProduction,
  isServiceProvider,
  isStartup,
} from 'utils';
import { Input, RenderIf } from 'components/components';
import { ServiceProviderType } from 'types/serviceProviders';
import { PartnerType } from 'packages/partner_repository';
import { RequestFiltersDropdownProps } from 'types/requests';
import { LabeledValue } from 'types';
import { StartupType } from 'types/startups';
import { AngelInvestorType } from 'types/angelInvestors';
import {
  requestTypeValues,
  requestTypeValuesForAI,
  useRequestFiltersHook,
  selectUsersValues,
} from '../../utils';

const RequestFiltersDropdown = (props: RequestFiltersDropdownProps) => {
  const {
    userType,
    serviceProviders,
    partners,
    startups,
    filters,
    angelInvestors,
    currentUserTab,
  } = props;
  const {
    getWorkspaceServiceProviders,
    getWorkspacePartners,
    getStartups,
    setRequestOverviewFilters,
    getAngelInvestors,
    setFilterShown,
    setCurrentUserTypeTab,
  } = props.actions;

  useEffect(() => {
    if (!isPartner(userType) && !isServiceProvider(userType)) {
      if (identity.isEmptyArray(serviceProviders)) {
        getWorkspaceServiceProviders();
      }
      if (identity.isEmptyArray(partners)) {
        getWorkspacePartners();
      }
    }

    if (!isStartup(userType) && !isAngelInvestor(userType) && identity.isEmptyArray(startups)) {
      getStartups();
    }

    if (!isAngelInvestor(userType) && !isStartup(userType) && identity.isEmptyArray(angelInvestors)) {
      getAngelInvestors();
    }
  }, []);

  const startupsOption: LabeledValue[] = useMemo(() => startups.map(
    ({ id: value, name: label }: StartupType) => ({ label, value, key: value }),
  ), [startups]);

  const angelInvestorsOption: LabeledValue[] = useMemo(() => angelInvestors?.map(
    ({ id: value, name: label }: AngelInvestorType) => ({ label, value, key: value }),
  ), [angelInvestors]);

  const serviceProvidersOptions: LabeledValue[] = useMemo(() => serviceProviders.map(
    ({ id: value, name: label }: ServiceProviderType) => ({ label, value, key: value }),
  ), [serviceProviders]);

  const partnersOptions: LabeledValue[] = useMemo(() => partners.map(
    ({ id: value, name: label }: PartnerType) => ({ label, value, key: value }),
  ), [partners]);

  const {
    state,
    initialState,
    setType,
    setAmount,
    setPartners,
    setServiceProviders,
    resetFilters,
    setStartups,
    setAngelInvestors,
    setAllFilters,
    setUsersType,
  } = useRequestFiltersHook();

  const {
    type,
    from,
    to,
    serviceProviderId,
    partnerId,
    startupId,
    angelInvestorId,
    usersType,
  } = state;

  useEffect(() => {
    setAllFilters({ data: filters });
  }, [filters]);

  const onFilter = () => {
    setRequestOverviewFilters({ data: state });
    setFilterShown({ isFilterShow: false });
    setCurrentUserTypeTab({ currentUserTab: usersType })
  }

  const onClose = () => {
    setAllFilters(filters);
    setFilterShown({ isFilterShow: false });
  }

  const onClearAll = () => {
    resetFilters();
    setRequestOverviewFilters({ data: initialState });
    setCurrentUserTypeTab({ currentUserTab: 'all' })
    onClose();
  }

  const isStartupOrAdminOrAI = useMemo(
    () => isAngelInvestor(userType) || isStartup(userType) || isEitherAdmin(userType),
    [userType],
  );

  const isStartupOrAdmin = useMemo(() => isStartup(userType) || isEitherAdmin(userType), [userType]);

  useEffect(() => {
    setUsersType({ data: currentUserTab })
  }, [currentUserTab])

  return (
    <div className="filters-dropdown">
      <div className="filters-dropdown__title-container">
        <h3 className="filters-dropdown__title">Filters</h3>
        <Button className="filters-buttons--button" onClick={onClose}><CloseIcon /></Button>
      </div>
      <RenderIf condition={[isStartupOrAdminOrAI, !isProduction].every(Boolean)}>
        <Input label="Request Type" layout="vertical">
          <Input.Radio
            values={isAngelInvestor(userType) ? requestTypeValuesForAI : requestTypeValues}
            value={type}
            onChange={({ target: { value: data } }) => setType({ data })}
          />
        </Input>
        <div className="filters-dropdown--divider" />
      </RenderIf>
      <RenderIf condition={[isStartupOrAdminOrAI, (type === 'service' || isProduction)].every(Boolean)}>
        <Input label="Service provider" layout="vertical">
          <Input.Select
            showSearch
            options={serviceProvidersOptions}
            value={serviceProviderId}
            mode="multiple"
            placeholder="Select service providers"
            maxTagCount="responsive"
            onChange={(data) => setServiceProviders({ data })}
          />
        </Input>
        <div className="filters-dropdown--divider" />
      </RenderIf>
      <RenderIf condition={[isStartupOrAdmin, type === 'partner'].every(Boolean)}>
        <Input label="Partner" layout="vertical">
          <Input.Select
            showSearch
            options={partnersOptions}
            value={partnerId}
            mode="multiple"
            placeholder="Select partners"
            maxTagCount="responsive"
            onChange={(data) => setPartners({ data })}
          />
        </Input>
        <div className="filters-dropdown--divider" />
      </RenderIf>
      <RenderIf condition={!isAngelInvestor(userType) && !isStartup(userType)}>
        <Input label="Select User" layout="vertical">
          <Input.Radio
            values={selectUsersValues}
            value={usersType}
            onChange={({ target: { value: data } }) => {
              setUsersType({ data })
            }}
          />
        </Input>
      </RenderIf>
      <RenderIf condition={usersType === 'angel-investor' && !isAngelInvestor(userType) && !isStartup(userType)}>
        <Input label="Angel Investor" layout="vertical">
          <Input.Select
            showSearch
            options={angelInvestorsOption}
            value={angelInvestorId}
            mode="multiple"
            placeholder="Select angel investors"
            maxTagCount="responsive"
            onChange={
              (data) => {
                setAngelInvestors({ data })
                setStartups({ data: [] })
              }
            }
          />
        </Input>
        <div className="filters-dropdown--divider" />
      </RenderIf>
      <RenderIf condition={usersType === 'startup' && !isAngelInvestor(userType) && !isStartup(userType)}>
        <Input label="Startup" layout="vertical">
          <Input.Select
            showSearch
            options={startupsOption}
            value={startupId}
            mode="multiple"
            placeholder="Select startups"
            maxTagCount="responsive"
            onChange={
              (data) => {
                setStartups({ data })
                setAngelInvestors({ data: [] })
              }
            }
          />
        </Input>
        <div className="filters-dropdown--divider" />
      </RenderIf>
      <RenderIf condition={(!isPartner(userType) && (type === 'service' || isProduction)) || isServiceProvider(userType)}>
        <div className="filters-dropdown__amount-container">
          <Input.Heading title="Amount" />
          <div className="filters-dropdown__amount-fields">
            <Input name="from" label="From" layout="vertical">
              <Input.InputNumber
                value={from}
                onChange={(e) => setAmount({ data: { field: 'from', value: identity.isPositiveNumber(e) ? e! : 0 } })}
              />
            </Input>
            <Input name="to" label="To" layout="vertical">
              <Input.InputNumber
                value={to}
                onChange={(e) => setAmount({ data: { field: 'to', value: identity.isPositiveNumber(e) ? e! : 0 } })}
              />
            </Input>
          </div>
        </div>
      </RenderIf>
      <div className="filters-buttons">
        <Button className="secondary-button" onClick={onClearAll}>Clear all</Button>
        <Button className="primary-button" onClick={onFilter}>Apply</Button>
      </div>
    </div>
  );
}

export default RequestFiltersDropdown;
