import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import showNotification from 'services/utils/showNotification';

import { orderByDescending } from 'packages/http_client';
import {
  identity,

} from 'utils';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getArticles,
  getArticleById as getArticleByIdApi,
} from 'packages/articles_repository/api';
import { DataPayload, IdPayload } from 'types/reduxTypes/ActionTypes';
import {
  GetArticlesParams,
} from 'packages/articles_repository';
import {
  types,
  setArticles,
  setArticleById,
} from '../../actions/articles';

function * handleGetArticles(action: PayloadAction<DataPayload<GetArticlesParams>>) {
  const filters = action.payload;

  const params: GetArticlesParams = {
    preload: true,
    order: orderByDescending('id'),
    limit: filters.data.limit,
    offset: filters.data.offset,
  };

  const { data, error, httpStatus } = yield call(getArticles, params);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
  } else {
    yield put(setArticles({ data }));
  }
}

function * handleGetArticleById(action: PayloadAction<IdPayload>) {
  const { id } = action.payload;

  const { data, error, httpStatus } = yield call(getArticleByIdApi, id, true);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setArticleById({ articleDetails: data }));
  }
}

export default function * articlesSagas() {
  yield all([
    takeLatest(types.GET_ARTICLES, handleGetArticles),
    takeLatest(types.GET_ARTICLE_BY_ID, handleGetArticleById),
  ]);
}
