import React from 'react';
import { Worker } from '@react-pdf-viewer/core';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import history from 'history_instance';
import App from './components/App';
import store from './redux/store';

import '@react-pdf-viewer/core/lib/styles/index.css';
import 'antd/dist/antd.min.css';
import './styles/index.scss';

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);
root.render(
  <Router history={history}>
    <Provider store={store}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
        <App />
      </Worker>
    </Provider>
  </Router>,
);
