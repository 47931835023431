import { createSelector } from 'reselect';

import { StateType } from 'types';
import { ArticleStateType } from 'types/reduxTypes/StateTypes';

const selectArticlesState = (state: StateType) => state.articlesReducer;

export const selectArticles = createSelector(
  selectArticlesState,
  (state: ArticleStateType) => state.articles,
);

export const selectArticleDetails = createSelector(
  selectArticlesState,
  (state: ArticleStateType) => state.articleDetails,
);
