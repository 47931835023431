import { DataPayload, IdPayload } from 'types/reduxTypes/ActionTypes';
import { SetArticlePayload } from 'types/reduxTypes/ActionTypes/ArticleTypes';
import { ResponseArticleType } from '../../../packages/articles_repository';

export const types = {
  SET_ARTICLES: 'SET_ARTICLES',
  GET_ARTICLES: 'GET_ARTICLES',
  SET_ARTICLE_BY_ID: 'SET_ARTICLE_BY_ID',
  GET_ARTICLE_BY_ID: 'GET_ARTICLE_BY_ID',
}

export const setArticles = (payload: DataPayload<ResponseArticleType[]>) => ({
  type: types.SET_ARTICLES,
  payload,
});

export const getArticles = (payload: {}) => ({
  type: types.GET_ARTICLES,
  payload,
});

export const setArticleById = (payload: SetArticlePayload) => ({
  type: types.SET_ARTICLE_BY_ID,
  payload,
});

export const getArticleById = (payload: IdPayload) => ({
  type: types.GET_ARTICLE_BY_ID,
  payload,
});
