import { API_ROOT } from 'packages/http_client/utils';

export const TECHNICALLICENSE_API = `${API_ROOT}/technicallicense`;

export const STAGE_API = `${API_ROOT}/technicallicensestage`;

export const INVESTMENT_STAGE_API = `${API_ROOT}/investmentstage`;

export const SECTOR_API = `${API_ROOT}/sector`;

export const TECHNICALLICENSE_ADGM_LICENSE = `${API_ROOT}/startupadgmlicense`;
export const TECHNICALLICENSE_INCENTIVE_AGREEMENT = `${API_ROOT}/startupincentiveagreement`;
