import { TechnicalHolderType as TechnicalLicense, TechnicalHolderStatusType, IncentiveAgreementType } from 'packages/technical_holder_repository';
import { TLHADGMLicenseType } from 'packages/technical_holder_repository/types/adgm_license';
import { TechnicalLicensesStateType } from 'types/reduxTypes/StateTypes';
import { TechnicalLicenseType } from 'types/technicalLicenses';
import { serviceProviderDefault } from './ServiceProvidersDefaults';

const defaultStatus: TechnicalHolderStatusType = {
  id: -1,
  code: '',
  name: '',
  createdAt: '',
}

export const defaultTechnicalLicense: TechnicalLicenseType = {
  balance: '',
  consumed: '',
  raised: '',
  about: '',
  id: -1,
  imageLink: '',
  industry: '',
  country: '',
  city: '',
  name: '',
  stage: '',
  type: '',
  employees: 0,
  serviceProvidersCount: 0,
  upcomingEventsCount: 0,
  founders: [],
  status: defaultStatus,
  website: '',
  moUSigned: false,
  moUStartDate: '',
  moUEndDate: '',
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPhone: '',
  contactRole: '',
}

export const defaultTechnicalLicensesADGMLicense: TLHADGMLicenseType = {
  address: '',
  expiryDate: '',
  issueDate: '',
  legalADGMName: '',
  legalSignatoryEmail: '',
  legalSignatoryName: '',
  legalSignatoryTitle: '',
  id: -1,
  aDGMLicenseNo: '',
  technicalLicenseID: 0,
  aDGMLicenseUrl: '',
  approvedRejectedStatus: false,
}

export const technicalLicenseDef: TechnicalLicense = {
  id: -1,
  name: '',
  about: '',
  stage: -1,
  website: '',
  imageLink: '',
  location: '',
  stageName: '',
  walletID: -1,
  wallet: null,
  status: defaultStatus,
  aDGMApprovedStatus: 0,
  incentiveAgreementApprovedStatus: 0,
  currentADGMLicense: {
    aDGMLicenseUrl: '',
    aDGMLicenseNo: '',
    id: -1,
  },
  currentADGMLicenseID: 0,
  currentIncentiveAgreement: null,
  currentIncentiveAgreementID: 0,
  acceptanceLetter: '',
  acceptanceLetterApprovedStatus: 0,
  moUSigned: false,
  moUStartDate: '',
  moUEndDate: '',
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPhone: '',
  contactRole: '',
}

export const defaultTechnicalLicenseIncentiveAgreement: IncentiveAgreementType = {
  file: '',
  id: 0,
  technicalLicenseID: 0,
  startDate: '',
  endDate: '',
  walletAmount: 0,
}

export const technicalLicensesDefaultState: TechnicalLicensesStateType = {
  filtersActive: false,
  filters: {
    locations: [],
    stage: 0,
    capitalRaised: '',
    incentiveAmount: '',
    fieldSelection: [],
    searchString: '',
  },
  incentiveAgreement: defaultTechnicalLicenseIncentiveAgreement,
  technicalLicenses: [],
  locations: [],
  adgmLicenseByTechnicalLicenseId: defaultTechnicalLicensesADGMLicense,
  stageOptions: [],
  investmentStageOptions: [],
  isLoading: false,
  details: {
    data: defaultTechnicalLicense,
    requests: [],
    teamMembers: [],
    teamMembersCount: 0,
    serviceProviders: [],
    serviceProvidersCount: 0,
    serviceProvider: serviceProviderDefault,
    requestsList: [],
  },
  technicalLicenseCount: 0,
  selectedTechnicalLicenseForReview: technicalLicenseDef,
};
