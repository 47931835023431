import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';

import { identity } from 'utils';
import { RenderIf } from 'components/components';
import { RequestUserTypeTabsProps, RequestTabsUserProps } from 'types/requests';
import './Tabs.scss';

const Tabs = (props: RequestUserTypeTabsProps) => {
  const {
    currentUserTab,
  } = props;
  const [selectedTab, setSelectedTab] = useState(currentUserTab ?? 'all');

  const {
    setCurrentUserTypeTab,
  } = props.actions

  const userTypeTabs = ['all', 'startup', 'angel-investor'];

  const tabTitles: any = {
    all: 'All',
    startup: 'Startup',
    'angel-investor': 'Angel Investors',
  };

  const handleOnTabChange = (value: RequestTabsUserProps) => {
    setCurrentUserTypeTab({ currentUserTab: value })
    setSelectedTab(value);
  }

  useEffect(() => {
    setSelectedTab(currentUserTab);
  }, [currentUserTab]);

  return (
    <RenderIf condition={identity.isFullArray(userTypeTabs)}>
      <div className="tab-buttons-lg">
        {userTypeTabs.map((tab: any) => (
          <Button
            key={tabTitles[tab]}
            className={classnames('tab-button-lg', { 'tab-button-active': selectedTab === tab })}
            onClick={() => handleOnTabChange(tab)}
          >
            {tabTitles[tab]}
          </Button>
        ))}
      </div>
    </RenderIf>
  );
}

export default Tabs;
