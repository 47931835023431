import React, { useMemo } from 'react';
import {
  AutoComplete, Badge, Button, Input,
} from 'antd';
import { useLocation } from 'react-router';
import classNames from 'classnames';

import { RenderIf } from 'components/components';
import routes from 'routes';
import { NotificationIcon, SearchIcon } from 'assets/icons';
import { identity, isTechnicalLicense, useDebounceSearch } from 'utils';
import { NavigationProps } from 'types/reduxTypes/ActionTypes/WorkspaceTypes';
import { ProfilePopover, HelpPopover, DropdownComp } from './components';
import { formatSearchResults } from './utils/helpers';

import './Navbar.scss';

const Navbar = (props: NavigationProps) => {
  const {
    actions, notificationsOpen, newCount, searchResults, userType,
  } = props;

  const location = useLocation();

  const onSearch = (q: string) => {
    if (identity.isTruthyString(q)) {
      actions.getSearchResult({ q });
      return;
    }
    actions.setNavigationSearchResults({ data: [] });
  }

  const { setSearch, search } = useDebounceSearch(300, onSearch);

  const toggle = () => {
    if (newCount > 0) {
      actions.setNotificationsSeen();
    }
    actions.toggleNotifications();
  }

  const onClear = () => {
    setSearch('');
    actions.setNavigationSearchResults({ data: [] })
  };

  const options = useMemo(() => formatSearchResults(searchResults), [searchResults]);

  return (
    <div className="navbar">

      <div className="input">
        <RenderIf condition={!isTechnicalLicense(userType)}>
          <div>
            <RenderIf condition={!(location?.pathname === routes.addMissingDetailsUrl)}>
              <AutoComplete
                options={options}
                allowClear
                notFoundContent={<p>No search results...</p>}
                onClear={onClear}
                value={search}
                onSelect={() => setSearch('')}
              >
                <Input
                  placeholder="Search..."
                  onChange={(e) => setSearch(e.target.value)}
                  prefix={<SearchIcon />}
                  bordered={false}
                />
              </AutoComplete>
            </RenderIf>
          </div>
        </RenderIf>
      </div>

      <div className="navbar-right">
        <RenderIf condition={!(location?.pathname === routes.addMissingDetailsUrl)}>
          <>
            <DropdownComp title="Create new" isfulfilled={false} />
            <RenderIf condition={false}>
              <HelpPopover />
            </RenderIf>
            <RenderIf condition={!isTechnicalLicense(userType)}>
              <Badge dot={newCount > 0}>
                <Button
                  icon={<NotificationIcon />}
                  shape="circle"
                  className={classNames('right-side-icon', notificationsOpen ? 'right-side-icon--open' : '')}
                  onClick={toggle}
                />
              </Badge>
            </RenderIf>
          </>
        </RenderIf>
        <ProfilePopover />
      </div>
    </div>
  )
}

export default Navbar;
