import { createSelector } from 'reselect';

import { StateType } from 'types';
import { UserStateType } from 'types/reduxTypes/StateTypes';

const selectUsersState = (state: StateType) => state.userReducer;

export const selectUsers = createSelector(
  selectUsersState,
  (state: UserStateType) => state.user,
);

export const selectToggleUserProfile = createSelector(
  selectUsersState,
  (state: UserStateType) => state.isVisible,
);

export default null;
