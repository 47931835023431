import React from 'react';
import { useLocation } from 'react-router';

import { ServiceProviderInfoProps } from 'types/serviceProviders/ServiceProviderDetails';
import { composeEditServiceProviderUrl, composeServiceProviderMoreDetailsUrl } from 'routes/routeComposers';
import { isEitherAdmin, identity } from 'utils';
import routes from 'routes';
import { TitleWithActions, ProfileOverview } from 'components/components';

import './ServiceProviderInfo.scss';

const NOT_SPECIFIED = 'Not Specified';

const ServiceProviderInfo = (props: ServiceProviderInfoProps) => {
  const {
    name, category, websiteLink, imageLink, id,
  } = props.serviceProviderInfo;
  const {
    firstName, lastName, email, phone, role,
  } = props.contactPerson;
  const { userType, userServiceProviderId } = props;

  const location = useLocation();

  const isMyCompanyProfile = identity.isTruthyNumber(userServiceProviderId)
    && location.pathname === routes.companyProfileUrl;

  const editUrl = isEitherAdmin(userType) ? composeEditServiceProviderUrl(id) : routes.editCompanyProfileUrl;

  return (
    <div className="service-provider-details__info-container">
      <TitleWithActions
        title="Service provider overview"
        buttonTitle={(isEitherAdmin(userType) || isMyCompanyProfile) ? 'profile' : 'More details'}
        link={(isEitherAdmin(userType) || isMyCompanyProfile) ? editUrl : composeServiceProviderMoreDetailsUrl(id)}
        isEditButton={isEitherAdmin(userType) || isMyCompanyProfile}
        showButton
      />
      <ProfileOverview
        imageUrl={imageLink}
        heading={name}
        subheading={`Service Provider | ${name}`}
        rightContent={[
          { label: 'category', value: category || NOT_SPECIFIED },
          { label: 'website', value: websiteLink || NOT_SPECIFIED },
          { label: 'contact person', value: firstName ? `${firstName} ${lastName}` : NOT_SPECIFIED },
          { label: 'email', value: email || NOT_SPECIFIED },
          { label: 'phone number', value: phone || NOT_SPECIFIED },
          { label: 'job title', value: role || NOT_SPECIFIED },
        ]}
        userType={userType}
      />
    </div>
  );
}

export default ServiceProviderInfo;
