import httpClient, {
  API_HOST,
  ApiResponseType,
  DeleteResourceSuccessResponseType,
  GetResourceSuccessResponseType,
  HttpRequestConfig,
  parsePreloadValue,
} from 'packages/http_client';

import { AxiosRequestConfig } from 'axios';

import {
  CreateServiceRequestApiResponseType,
  CreateServiceRequestCommentApiResponseType,
  CreateServiceRequestCommentPayload,
  CreateServiceRequestCommentType,
  CreateServiceRequestDocumentApiRequestPayload,
  CreateServiceRequestDocumentApiResponseType,
  CreateServiceRequestDocumentType,
  CreateServiceRequestItemApiResponseType,
  CreateServiceRequestItemPayload,
  CreateServiceRequestItemResponse,
  PostServiceRequestItemResponse,
  UpdateServiceRequestDocumentResponse,
  CreateServiceRequestItemType,
  CreateServiceRequestPayload,
  CreateServiceRequestResponse,
  CreateServiceRequestType,
  GetRequestApiResponse,
  GetRequestCommentsListApiResponse,
  GetRequestCountApiResponse,
  GetRequestListResponse,
  GetRequestsCountResponse, GetServiceRequestByIdApiResponse,
  GetServiceRequestByIdResponse,
  GetServiceRequestCommentsListResponse,
  GetServiceRequestCommentsParams,
  GetServiceRequestItemsApiResponse,
  GetServiceRequestItemsListResponse,
  GetServiceRequestItemsParams,
  GetServiceRequestsDocumentsParams,
  GetServiceRequestsParams,
  GetTotalPriceApiParamsType,
  ServiceRequestDocumentType,
  ServiceRequestTotalPriceApiResponse,
  UpdateRequestItemPayload,
  UpdateServiceRequestApiResponseType,
  UpdateServiceRequestItemResponse,
  UpdateServiceRequestItemType,
  UpdateServiceRequestPayload,
  UpdateServiceRequestResponse,
  UpdateServiceRequestType,
  PostServiceRequestDocumentType,
  PostServiceRequestDocumentApiResponseType,
  UpdateServiceRequestDocumentApiResponseType,
  PutServiceRequestDocumentType,
  UpdateServiceRequestDocumentPayload,
  UpdateServiceRequestDocumentType,
} from './types';
import {
  convertCreateServiceRequestDocumentsPayload,
  convertCreateServiceRequestItemPayload,
  convertUpdateServiceRequestDocumentPayload,
  convertGetRequestListResponse,
  convertGetRequestsCount,
  convertGetServiceRequestCommentList,
  convertGetServiceRequestItemListResponse,
  convertGetServiceRequestResponse,
  convertServiceRequestBody,
  convertServiceRequestCommentBody,
  convertUpdateServiceRequestBody,
  REQUEST_API,
  requestsQuery,
  SERVICE_REQUEST_API,
  SERVICE_REQUEST_COMMENT,
  SERVICE_REQUEST_DOCUMENT_API,
  SERVICE_REQUEST_ITEM_API,
} from './utils';

const getRequestsApi = (query?: GetServiceRequestsParams) => {
  const url = REQUEST_API;
  const params = requestsQuery(query);
  const config: HttpRequestConfig = { params }
  return httpClient.get<ApiResponseType<GetRequestApiResponse>>(url, config);
}

const getServiceRequestByIdApi = (id: number, preload?: boolean) => {
  const url = `${SERVICE_REQUEST_API}/${id}`;
  const config: HttpRequestConfig = {
    params: {
      $preload: parsePreloadValue(preload),
    },
  }
  return httpClient.get<ApiResponseType<GetServiceRequestByIdApiResponse>>(url, config)
}

const getServiceRequestByStartupIdApi = (id: number, preload?: boolean) => {
  const url = `${REQUEST_API}?startup_id=${id}`;
  const config: HttpRequestConfig = {
    params: {
      $preload: parsePreloadValue(preload),
    },
  }
  return httpClient.get<ApiResponseType<GetServiceRequestByIdApiResponse>>(url, config)
}

const getServiceRequestByAngelInvestorIdApi = (id: number, preload?: boolean) => {
  const url = `${REQUEST_API}?angel_investor_id=${id}`;
  const config: HttpRequestConfig = {
    params: {
      $preload: parsePreloadValue(preload),
    },
  }
  return httpClient.get<ApiResponseType<GetServiceRequestByIdApiResponse>>(url, config)
}

const createServiceRequestApi = (payload: CreateServiceRequestPayload) => (
  httpClient.post<ApiResponseType<CreateServiceRequestApiResponseType>>(SERVICE_REQUEST_API, payload)
);

const updateServiceRequestApi = (id: number, payload: UpdateServiceRequestPayload) => {
  const url = `${SERVICE_REQUEST_API}/${id}`;
  return httpClient.put<ApiResponseType<UpdateServiceRequestApiResponseType>>(url, payload);
}

const deleteServiceRequestByIdApi = (id: number) => {
  const url = `${SERVICE_REQUEST_API}/${id}`;
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url);
}

const getServiceRequestItemsApi = (params?: GetServiceRequestItemsParams) => {
  const url = SERVICE_REQUEST_ITEM_API;
  const config: HttpRequestConfig = {
    params,
  }
  return httpClient.get<ApiResponseType<GetServiceRequestItemsApiResponse>>(url, config);
}

const createServiceRequestItemApi = (payload: CreateServiceRequestItemPayload) => (
  httpClient.post<ApiResponseType<CreateServiceRequestItemApiResponseType>>(SERVICE_REQUEST_ITEM_API, payload)
);

const updateServiceRequestItemApi = (id: number, payload: UpdateRequestItemPayload) => {
  const url = `${SERVICE_REQUEST_ITEM_API}/${id}`;
  return httpClient.put<ApiResponseType<UpdateServiceRequestApiResponseType>>(url, payload);
}

const deleteServiceRequestItemByIdApi = (id: number) => {
  const url = `${SERVICE_REQUEST_ITEM_API}/${id}`;
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url);
}

const deleteServiceRequestItemsApi = (params?: GetServiceRequestItemsParams) => {
  const url = SERVICE_REQUEST_ITEM_API;
  const config: HttpRequestConfig = { params };
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url, config);
}

const createServiceRequestDocumentsApi = (payload: CreateServiceRequestDocumentApiRequestPayload) => (
  httpClient.post<ApiResponseType<CreateServiceRequestDocumentApiResponseType>>(SERVICE_REQUEST_DOCUMENT_API, payload)
);

const postServiceRequestDocumentsApi = (payload: PostServiceRequestDocumentType) => (
  httpClient.post<ApiResponseType<PostServiceRequestDocumentApiResponseType>>(SERVICE_REQUEST_DOCUMENT_API, payload)
);

const putServiceRequestDocumentsApi = (payload: PutServiceRequestDocumentType) => (
  httpClient.put<ApiResponseType<PostServiceRequestDocumentApiResponseType>>(SERVICE_REQUEST_DOCUMENT_API, payload)
);

const updateServiceRequestDocumentsApi = (payload: UpdateServiceRequestDocumentPayload) => {
  const url = `${SERVICE_REQUEST_DOCUMENT_API}/${payload.id}`;
  return httpClient.put<ApiResponseType<UpdateServiceRequestDocumentApiResponseType>>(url, payload);
}

const getServiceRequestDocumentsApi = (params: GetServiceRequestsDocumentsParams) => {
  const url = SERVICE_REQUEST_DOCUMENT_API;
  const config: HttpRequestConfig = {
    params,
  };

  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<ServiceRequestDocumentType[]>>>(url, config);
}

const getServiceRequestCommentsListApi = (params?: GetServiceRequestCommentsParams) => {
  const url = SERVICE_REQUEST_COMMENT;
  const config: HttpRequestConfig = {
    params,
  };
  return httpClient.get<ApiResponseType<GetRequestCommentsListApiResponse>>(url, config);
}

const getServiceRequestTotaPriceApi = (payload?: GetTotalPriceApiParamsType) => {
  const SEARCH_PARAMS = payload?.searchParams
  const ACCESS_TOKEN = payload?.accessToken ? payload?.accessToken : ''
  const URL = `${API_HOST}/api/adnic/?${SEARCH_PARAMS}`
  // Define the headers you want to include in the request.
  const headers: AxiosRequestConfig['headers'] = {
    Authorization: `Bearer ${ACCESS_TOKEN}`, // Assuming you're using Bearer token authentication.
    credentials: 'include',
  };

  // Define the Axios configuration object with the headers.
  const axiosConfig: AxiosRequestConfig = {
    headers,
  };
  return httpClient.get<ApiResponseType<ServiceRequestTotalPriceApiResponse>>(URL, axiosConfig);
}

const createServiceRequestCommentApi = (payload: CreateServiceRequestCommentPayload) => (
  httpClient.post<ApiResponseType<CreateServiceRequestCommentApiResponseType>>(SERVICE_REQUEST_COMMENT, payload)
);

const getRequestsCountApi = (query?: GetServiceRequestsParams) => {
  const url = REQUEST_API;
  const params = requestsQuery({ ...query, isCount: true });
  const config: HttpRequestConfig = { params };
  return httpClient.get<ApiResponseType<GetRequestCountApiResponse>>(url, config);
}

export const getRequests = async (params?: GetServiceRequestsParams): Promise<GetRequestListResponse> => {
  const response = await getRequestsApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertGetRequestListResponse(data?.result!) };
}

export const getRequestsCount = async (params?: GetServiceRequestsParams): Promise<GetRequestsCountResponse> => {
  const response = await getRequestsCountApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertGetRequestsCount(data?.result) };
}

export const getServiceRequestById = async (id: number, preload?: boolean):
Promise<GetServiceRequestByIdResponse> => {
  const response = await getServiceRequestByIdApi(id, preload);
  const { error, httpStatus, data } = response;

  return { error, httpStatus, data: convertGetServiceRequestResponse(data?.result!) };
}

export const getServiceRequestByStartupId = async (id: number, preload?: boolean):
Promise<any> => {
  const response = await getServiceRequestByStartupIdApi(id, preload);
  const { error, httpStatus, data } = response;

  return { error, httpStatus, data: data?.result! };
}

export const getServiceRequestByAngelInvestorId = async (id: number, preload?: boolean):
Promise<any> => {
  const response = await getServiceRequestByAngelInvestorIdApi(id, preload);
  const { error, httpStatus, data } = response;

  return { error, httpStatus, data: data?.result! };
}

export const createServiceRequest = async (payload: CreateServiceRequestType):
Promise<CreateServiceRequestResponse> => {
  const response = await createServiceRequestApi(convertServiceRequestBody(payload));
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id[0] };
}

export const updateServiceRequest = async (id: number, payload?: UpdateServiceRequestType):
Promise<UpdateServiceRequestResponse> => {
  const response = await updateServiceRequestApi(id, convertUpdateServiceRequestBody(payload));
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const deleteServiceRequestById = async (id: number) => {
  const response = await deleteServiceRequestByIdApi(id);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const getServiceRequestItems = async (params?: GetServiceRequestItemsParams):
Promise<GetServiceRequestItemsListResponse> => {
  const response = await getServiceRequestItemsApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertGetServiceRequestItemListResponse(data?.result) };
}

export const createServiceRequestItem = async (payload: CreateServiceRequestItemType):
Promise<CreateServiceRequestItemResponse> => {
  const response = await createServiceRequestItemApi(convertCreateServiceRequestItemPayload(payload));
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id[0] };
}

export const updateServiceRequestItem = async (id: number, payload: UpdateServiceRequestItemType):
Promise<UpdateServiceRequestItemResponse> => {
  const response = await updateServiceRequestItemApi(id, convertCreateServiceRequestItemPayload(payload));
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const deleteServiceRequestItemById = async (id: number) => {
  const response = await deleteServiceRequestItemByIdApi(id);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const deleteServiceRequestItems = async (params?: GetServiceRequestItemsParams) => {
  const response = await deleteServiceRequestItemsApi(params);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const createServiceRequestDocument = async (payload: CreateServiceRequestDocumentType):
  Promise<CreateServiceRequestItemResponse> => {
  const response = await createServiceRequestDocumentsApi(convertCreateServiceRequestDocumentsPayload(payload));
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id[0] };
}

export const getServiceRequestDocuments = async (params: GetServiceRequestsDocumentsParams):
Promise<ApiResponseType<ServiceRequestDocumentType[]>> => {
  const response = await getServiceRequestDocumentsApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.result };
}

export const postServiceRequestDocuments = async (params: PostServiceRequestDocumentType):
Promise<PostServiceRequestItemResponse> => {
  const response = await postServiceRequestDocumentsApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data };
}

export const putServiceRequestDocuments = async (params: PutServiceRequestDocumentType):
Promise<PostServiceRequestItemResponse> => {
  const response = await putServiceRequestDocumentsApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data };
}

export const updateServiceRequestDocuments = async (params: UpdateServiceRequestDocumentType):
Promise<UpdateServiceRequestDocumentResponse> => {
  const response = await updateServiceRequestDocumentsApi(convertUpdateServiceRequestDocumentPayload(params));
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data };
}

export const getServiceRequestCommentsList = async (params?: GetServiceRequestCommentsParams):
Promise<GetServiceRequestCommentsListResponse> => {
  const response = await getServiceRequestCommentsListApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertGetServiceRequestCommentList(data?.result) };
}

export const createServiceRequestComment = async (payload: CreateServiceRequestCommentType) => {
  const response = await createServiceRequestCommentApi(convertServiceRequestCommentBody(payload));
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id };
}

export const getTotalPrice = async (payload: GetTotalPriceApiParamsType) => {
  const response = await getServiceRequestTotaPriceApi(payload);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data }
}
