import React from 'react';

import { identity } from 'utils';
import { TitleWithActionsProps } from 'types/componentTypes';
import { Title, ButtonsWithActions } from './components';
import RenderIf from '../RenderIf';
import Text from '../Text';
import SearchBar from '../SearchBar';
import StaticNotification from '../Notification/StaticNotification';

import './TitleWithActions.scss';

/**
 * @component TitleWithActions
 * Component that renders title and optional subtitle and action buttons.
 * Size refers to the font size of title. Default value is (s).
 *
 * (xxs): 14px
 *
 * (xs): 16px
 *
 * (s): 18px
 *
 * (m): 22px
 *
 * (l): 31px
 * @param {TitleWithActionsProps} props
 */
const TitleWithActions = (props: TitleWithActionsProps) => {
  const { buttons, subtitle } = props;
  return (
    <div className="title-with-actions">
      <div className="title-with-actions--top">
        <div className="title-with-actions--left">
          <div className="title-with-actions__container">
            <Title size={props.size} title={props.title} titleWeight={props.titleWeight} />
            <RenderIf condition={props.showButton! || identity.isFullArray(buttons)}>
              <ButtonsWithActions
                buttonTitle={props.buttonTitle}
                link={props.link}
                buttons={buttons}
                isEditButton={props.isEditButton}
                disableEditButton={props.disableEditButton}
              />
            </RenderIf>
          </div>
          <RenderIf condition={identity.isTruthyString(subtitle)}>
            <Text text={subtitle!} size={props.subtitleSize!} weight={props.subtitleWeight!} />
          </RenderIf>
        </div>
        <SearchBar
          showAllCategories={props.showAllCategories}
          onClickFilterButton={props.onClickFilterButton}
          showSearchBar={props.showSearchBar}
          showFilterButton={props.showFilterButton}
          filtersActive={props.filtersActive}
          showExportButton={props.showExportButton}
          exportDataModel={props.exportDataModel}
          filterButtonType={props.filterButtonType}
          filterDropdownElements={props.filterDropdownElements}
          showFilterByUserType={props.showFilterByUserType}
          setCurrentUserTypeTab={props.setCurrentUserTypeTab}
        />
      </div>
      <RenderIf condition={props.filtersActive}>
        <StaticNotification type="warning" message="You are currently viewing filtered items. To view all items clear filters." />
      </RenderIf>
    </div>
  )
}

TitleWithActions.defaultProps = {
  showButton: false,
  subtitle: '',
  subtitleWeight: 300,
  subtitleSize: 'xs',
  showSearchBar: false,
  showAllCategories: false,
  showFilterButton: false,
  filtersActive: false,
  showExportButton: false,
  filterButtonType: 'default',
  showFilterByUserType: false,
}

export default TitleWithActions;
