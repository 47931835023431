import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Spin } from 'antd';
// import { selectStartupDetailsRequests } from 'redux/selectors/startups';
import routes from 'routes';
import { composeEditStartupUrl } from 'routes/routeComposers';
import StaticNotification from 'components/components/Notification/StaticNotification/View';
import {
  getStartupById as getStatusStartup,
  StartupType,
  StartupStatusType,
} from 'packages/startup_repository';
// import { convertGetRequestResponse } from 'packages/service_request_repository';
import {
  About,
  Breadcrumb,
  RenderIf,
  Requests,
  StartupWallet,
  StatCards,
  ExpandableTable,
  TitleWithActions,
  ProfileOverview,
} from 'components/components';
import {
  identity,
  getRowKey,
  isPartner,
  getImageLink,
  isEitherAdmin,
  isStartupFounder,
  isExpired,
} from 'utils';
import { RequestType } from 'types/requests';
import { StartupDetailsProps } from 'types/startups/startupDetails';
import { getServiceRequestByStartupId } from 'packages/service_request_repository/api';
import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';
import { CohortDetails } from './components';
import { serviceProvidersColumns, startupTeamMembersColumns } from '../utils/constants/columns';

import './StartupDetails.scss';

const StartupDetails = (props: StartupDetailsProps) => {
  const {
    aboutStartup,
    info,
    statCards,
    teamMembers,
    // requests,
    userType,
    isLoading,
    partnersStatCards,
    userStartupId,
    startupServiceProviders,
  } = props;
  const {
    getStartupById,
    getStartupTeamMembers,
    getRequestsByOwnerId,
    getStartupServiceProviders,
  } = props.actions

  const params: { id?: string } = useParams();
  const { id } = params;

  const [startupStatus, setStartupStatus] = useState<StartupStatusType>();
  const [startupRequests, setStartupRequests] = useState<RequestType[]>();
  const [startupDetails, setStartupDetails] = useState<StartupType>();

  const {
    facebook,
    instagram,
    twitter,
    linkedIn,
    primarySector,
    secondarySector,
    founderEmail,
    stageName,
    numberOfEmployees,
    id: sId,
    name,
    imageLink,
    location,
    wallet,
  } = info;

  const rightContent = useMemo(() => ([
    {
      label: 'industry',
      value: identity.isTruthyString(primarySector?.name) ? primarySector?.name : secondarySector?.name,
    },
    {
      label: 'Founder Email',
      value: identity.isTruthyString(founderEmail) ? founderEmail : NOT_SPECIFIED,
    },
    { label: 'stage', value: stageName },
    { label: 'employees', value: numberOfEmployees },
  ]), [stageName, numberOfEmployees, founderEmail, primarySector, secondarySector]);

  const socialMediaLinks = useMemo(() => ({
    facebook, instagram, twitter, linkedIn,
  }), [facebook, instagram, twitter, linkedIn]);

  const startupId = useMemo(() => (identity.isTruthyNumber(userStartupId) ? userStartupId : parseInt(id!, 10)), [id]);

  useEffect(() => {
    async function getStartupDetails() {
      if (identity.isTruthyNumber(startupId)) {
        const res = await getStatusStartup(startupId, true);
        setStartupStatus(res?.data?.status)
        setStartupDetails(res?.data)
      }
    }
    getStartupDetails()

    if (identity.isTruthyNumber(startupId)) {
      getStartupById({ id: startupId, owner: 'startup', isUpdate: true });
      getStartupTeamMembers({ id: startupId });
      getRequestsByOwnerId({ id: startupId, owner: 'startup' });
    }
    getStartupServiceProviders({ query: { startupId, isPreload: true } });
  }, [startupId]);

  useEffect(() => {
    async function getRequestsById() {
      const result = await getServiceRequestByStartupId(startupId, true)
      const cR = result?.data
      const sorted = cR.sort((a: any, b: any) => Date.parse(b.applicationDate) - Date.parse(a.applicationDate))
      const filteredR = sorted.slice(0, 5);
      setStartupRequests(filteredR)
    }
    getRequestsById()
  }, [startupId])

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div className="startup-details">
          <RenderIf condition={startupId !== userStartupId}>
            <Breadcrumb navigateTo={routes.startupsUrl} routeName="startups" />
          </RenderIf>
          <RenderIf condition={isExpired(startupStatus?.code!)}>
            <StaticNotification
              message="The incentives period for this startup has ended and they no longer can apply for any incentives."
              type="warning"
            />
          </RenderIf>
          <TitleWithActions
            title="Startup overview"
            buttonTitle="profile"
            link={startupId === userStartupId ? routes.editStartupProfileUrl : composeEditStartupUrl(sId)}
            isEditButton
            showButton={isEitherAdmin(userType) || (isStartupFounder(userType) && startupId === userStartupId)}
          />
          <ProfileOverview
            heading={name}
            imageUrl={getImageLink(imageLink!)}
            subheadingIsLocation={identity.isTruthyString(location)}
            subheading={location}
            rightContent={rightContent}
            socialMediaLinks={socialMediaLinks}
            userType={userType}
          />
          <RenderIf condition={identity.isObjWithChildren(wallet) && isEitherAdmin(userType)}>
            <StartupWallet
              wallet={wallet}
              includeGraph
              showInfo
              showTransactionButton
              startupId={startupId}
            />
          </RenderIf>
          <RenderIf condition={!isPartner(userType)}>
            <About
              title={`About ${name}`}
              isStartup={!isPartner(userType)}
              description={aboutStartup}
              startupDetails={startupDetails}
            />
          </RenderIf>
          <RenderIf condition={isPartner(userType)}>
            <About title={`About ${name}`} description={aboutStartup} />
          </RenderIf>
          <RenderIf condition={!isPartner(userType)}>
            <CohortDetails />
          </RenderIf>
          <RenderIf condition={isEitherAdmin(userType)}>
            <Requests component="startup" requests={startupRequests!} />
          </RenderIf>
          <StatCards cards={isPartner(userType) ? partnersStatCards : statCards} />
          <ExpandableTable
            collapseHeader={`Team Members (${teamMembers.length})`}
            dataSource={teamMembers}
            columns={startupTeamMembersColumns}
            rowKey={getRowKey(teamMembers, 'startup-details-team-member', 'id')}
          />
          <ExpandableTable
            className="service-providers"
            collapseHeader={`Service Providers working with ${name}`}
            dataSource={startupServiceProviders}
            columns={serviceProvidersColumns}
            rowKey={getRowKey(startupServiceProviders, 'startup-details-service-provider', 'id')}
          />
        </div>
      </Spin>
    </div>
  );
}

export default StartupDetails;
