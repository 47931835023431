import React, { useEffect } from 'react';

import { identity } from 'utils';
import routes from 'routes';
import { ProfileOverview, TitleWithActions } from 'components/components';
import { LabeledValue } from 'types';
import { HUB71AdminProps } from 'types/user';

const Hub71Admin = (props: HUB71AdminProps) => {
  const { user } = props;
  const {
    email, designation, photo, firstName, lastName, userType,
  } = user;

  useEffect(() => {
    if (!identity.isPositiveNumber(user.id)) {
      props.actions.getMyProfileData();
    }
  }, [user]);

  const rightContent: LabeledValue[] = [
    { label: 'Email', value: email },
    { label: 'Job title', value: designation },
  ];

  if (!identity.isPositiveNumber(user.id)) {
    return null;
  }

  return (
    <div className="my-profile">
      <TitleWithActions
        title="My profile"
        link={routes.editMyProfileUrl}
        buttonTitle="profile"
        isEditButton
        showButton
      />
      <ProfileOverview
        heading={`${firstName} ${lastName}`}
        imageUrl={photo}
        rightContent={rightContent}
        subheading="Hub71 employee"
        userType={userType}
      />
    </div>
  );
}

export default Hub71Admin;
