import React, { useMemo } from 'react';
import classnames from 'classnames';
import moment from 'moment';

import {
  identity,
} from 'utils';
import { RenderIf, Text } from 'components/components';
import { composeArticlesUrl } from 'routes/routeComposers';
import { ArticlesSectionProps } from 'packages/articles_repository/types/articlesOverview';
import { formatSpaceMDY } from '../../../utils/constants/formatters';

import './ArticleRow.scss';

const ArticleRow = (props: ArticlesSectionProps) => {
  const { request, highlight } = props;

  const {
    id,
    title: articleTitle,
    published_at,
    author,
    banner,
    source,
    subTitle,
    content,
  } = request;

  const requestData = useMemo(() => {
    const date = moment(published_at).format(formatSpaceMDY);
    return {
      date,
    }
  }, [request]);

  if (!identity.isObjWithChildren(request)) {
    return null;
  }

  const {
    date,
  } = requestData;

  return (
    <RenderIf condition={identity.isTruthyNumber(id)}>
      <div className={classnames('article-row', { 'highlighted-row': highlight })}>
        <div className="article-row__info-container">
          <RenderIf condition={banner}>
            <div className="article-row__info-container--right">
              {/* eslint-disable-next-line */}
              <a href={composeArticlesUrl(id)}>
                <img
                  src={banner}
                  alt=""
                  className={classnames('article-row__info-container__logo')}
                />
              </a>
            </div>
          </RenderIf>
          <div className="article-row__info-container--left">
            <div className="article-author-source-container">
              <RenderIf condition={identity.isNotNull(author)}>
                <div className="article-author">
                  <Text text={author} size="xxs" />
                </div>
              </RenderIf>
              <RenderIf condition={identity.isNotNull(source)}>
                <span>in</span>
                <div className="article-source">
                  <Text text={source} size="xxs" />
                </div>
              </RenderIf>
            </div>
            <div className="article-info">
              {/* eslint-disable-next-line */}
              <a href={composeArticlesUrl(id)}>
                <h2>{articleTitle}</h2>
                <div className="article-description">
                  <Text text={subTitle} size="xs" />
                </div>
                <div className="article-short-description">
                  <p>{`${content.slice(0, 200).replace(/(<([^>]+)>)/gi, '')}...`}</p>
                </div>
              </a>
              <RenderIf condition={identity.isNotNull(date)}>
                <div className="article-row__request-info--row">
                  <span className="article-row__request-info--row__details">{date}</span>
                </div>
              </RenderIf>
            </div>
          </div>
        </div>
      </div>
    </RenderIf>
  );
}

ArticleRow.defaultProps = {
  highlight: false,
  request: Object,
};

export default ArticleRow;
