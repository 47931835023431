import { PayloadAction } from '@reduxjs/toolkit';

import { TechnicalHolderType as TechnicalLicense } from 'packages/technical_holder_repository';
import { technicalLicenseDef } from 'redux/utils';
import { SetAllType, SetFieldType, types } from './actions';

const initialState: TechnicalLicense = technicalLicenseDef;

const handleSetAll = (state: TechnicalLicense, payload: SetAllType) => payload.technicalLicense;

const handleSetField = (state: TechnicalLicense, payload: SetFieldType) => (
  { ...state, [payload.field]: payload.value });

const reducer = (state: TechnicalLicense, action: PayloadAction<SetAllType | SetFieldType>) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ALL: {
      return handleSetAll(state, <SetAllType>payload);
    }
    case types.SET_FIELD: {
      return handleSetField(state, <SetFieldType>payload);
    }
    default:
      return state;
  }
}

export { reducer, initialState };
