import produce from 'immer';

import { ArticleStateType } from 'types/reduxTypes/StateTypes';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { RequestActionType } from 'types/reduxTypes/ActionTypes/ArticleTypes';
import {
  ResponseArticleType, ArticleType as AllArticleType, SetArticlesPayload,
} from 'packages/articles_repository';
import { types } from '../../actions/articles';
import { defaultArticlesState } from '../../utils';

const defaultState: ArticleStateType = defaultArticlesState;

const handleSetArticles = (state: ArticleStateType, payload: DataPayload<ResponseArticleType[]>) => {
  state.articles = payload.data;
  return state;
}

const handleSetArticleDetails = (state: ArticleStateType, payload: SetArticlesPayload) => {
  state.articleDetails = payload.articleDetails;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: ArticleStateType = defaultState, action: RequestActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ARTICLES: {
      return handleSetArticles(state, <DataPayload<AllArticleType[]>>payload);
    }
    case types.SET_ARTICLE_BY_ID: {
      return handleSetArticleDetails(state, <SetArticlesPayload>payload);
    }
    default: {
      return state;
    }
  }
});
