import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Spin } from 'antd';
import routes from 'routes';
import { composeEditTechnicalLicenseUrl } from 'routes/routeComposers';
import StaticNotification from 'components/components/Notification/StaticNotification/View';
import {
  Breadcrumb,
  RenderIf,
  // Requests,
  ExpandableTable,
  TitleWithActions,
  ProfileOverview,
  AboutTLH,
} from 'components/components';
import {
  identity,
  getRowKey,
  getImageLink,
  isEitherAdmin,
  isExpired,
  isTechnicalLicense,
} from 'utils';
import { TechnicalLicenseDetailsProps } from 'types/technicalLicenses/technicalLicenseDetails';
import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';
import {
  getTechnicalLicenseById as getStatusTechnicalLicense,
  TechnicalHolderType,
  TechnicalHolderStatusType,
} from 'packages/technical_holder_repository'
import { startupTeamMembersColumns, technicalLicenseTeamMembersColumn } from '../utils/constants/columns';

import './TechnicalLicenseDetails.scss';

const TechnicalLicenseDetails = (props: TechnicalLicenseDetailsProps) => {
  const {
    aboutTechnicalLicense,
    info,
    teamMembers,
    // requests,
    userType,
    isLoading,
    userTechnicalLicenseId,
  } = props;
  const {
    getTechnicalLicenseById,
    getTechnicalLicenseMembers,
    getRequestsByOwnerId,
  } = props.actions

  const params: { id?: string } = useParams();
  const { id } = params;

  const [technicalLicenseStatus, setTechnicalLicenseStatus] = useState<TechnicalHolderStatusType>();
  const [technicalLicenseDetails, setTechnicalLicenseDetails] = useState<TechnicalHolderType>();

  const {
    facebook,
    instagram,
    twitter,
    linkedIn,
    id: sId,
    name,
    imageLink,
    location,
  } = info;

  const rightContent = useMemo(() => ([
    {
      label: 'industry',
      value: 'Technical License Holder',
    },
    {
      label: 'Primary Email',
      value: identity.isTruthyString(teamMembers[0]?.email) ? teamMembers[0]?.email : NOT_SPECIFIED,
    },
    { label: 'employees', value: teamMembers?.length },
  ]), [teamMembers]);

  const socialMediaLinks = useMemo(() => ({
    facebook, instagram, twitter, linkedIn,
  }), [facebook, instagram, twitter, linkedIn]);

  const technicalLicenseId = useMemo(
    () => (identity.isTruthyNumber(userTechnicalLicenseId) ? userTechnicalLicenseId : parseInt(id!, 10)),
    [id],
  );

  useEffect(() => {
    async function getTechnicalLicenseDetails() {
      if (identity.isTruthyNumber(technicalLicenseId)) {
        const res = await getStatusTechnicalLicense(technicalLicenseId!, true);
        setTechnicalLicenseStatus(res?.data?.status)
        setTechnicalLicenseDetails(res?.data)
      }
    }
    getTechnicalLicenseDetails()

    if (identity.isTruthyNumber(technicalLicenseId)) {
      getTechnicalLicenseById({ id: technicalLicenseId, owner: 'technical-license', isUpdate: true });
      getTechnicalLicenseMembers({ id: technicalLicenseId });
      getRequestsByOwnerId({ id: technicalLicenseId, owner: 'technical-license' });
    }
  }, [technicalLicenseId]);

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div className="technicallicense-details">
          <RenderIf condition={technicalLicenseId !== userTechnicalLicenseId}>
            <Breadcrumb navigateTo={routes.technicalLicensesUrl} routeName="Technical License" />
          </RenderIf>
          <RenderIf condition={isExpired(technicalLicenseStatus?.code!)}>
            <StaticNotification
              message="The incentives period for this Technical license has ended and they no longer can apply for any incentives."
              type="warning"
            />
          </RenderIf>
          <TitleWithActions
            title="Technical License overview"
            buttonTitle="profile"
            link={
              technicalLicenseId === userTechnicalLicenseId
                ? routes.editCompanyProfileUrl
                : composeEditTechnicalLicenseUrl(sId)
            }
            isEditButton
            showButton={
              isEitherAdmin(userType)
              || (isTechnicalLicense(userType) && technicalLicenseId === userTechnicalLicenseId)
            }
          />
          <ProfileOverview
            heading={name}
            imageUrl={getImageLink(imageLink!)}
            subheadingIsLocation={identity.isTruthyString(location)}
            subheading={location}
            rightContent={rightContent}
            socialMediaLinks={socialMediaLinks}
            userType={userType}
          />

          <AboutTLH
            title={`About ${name}`}
            isTechnicalLicense={isTechnicalLicense(userType)}
            description={aboutTechnicalLicense}
            technicalLicenseDetails={technicalLicenseDetails}
          />

          <ExpandableTable
            collapseHeader={`Team Members (${teamMembers.length})`}
            dataSource={teamMembers}
            columns={isTechnicalLicense(userType) ? technicalLicenseTeamMembersColumn : startupTeamMembersColumns}
            rowKey={getRowKey(teamMembers, 'technicallicense-details-team-member', 'id')}
          />
        </div>
      </Spin>
    </div>
  );
}

export default TechnicalLicenseDetails;
