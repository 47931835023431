import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectIsFilterShown, selectIsRowTableLayout } from 'redux/selectors/workspace';
import { setFilterShown } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  isRowTableLayout: selectIsRowTableLayout(state),
  isFilterShown: selectIsFilterShown(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setFilterShown,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
