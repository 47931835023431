import React from 'react';

import Overview from '../../../components/Overview';
import Modal from '../../../components/Modal';
import RequestList from '../../../components/RequestList';
import { StartupDetailsProps } from '../../../../types/serviceProviders/ServiceProviderDetails';
import ProfileOverview from '../../../components/ProfileOverview';

import './StartupDetails.scss';

const StartupDetails = (props: StartupDetailsProps) => {
  const {
    requests, isOpen, setIsOpen, serviceName, userType,
  } = props;
  const {
    name, about, imageLink, industry, stage, city, country, website, founders,
  } = props.startup;

  const returnFoundersString = (): string => {
    const foundersSize = founders.length;
    return founders.map((founder: string, index: number) => (index + 1 !== foundersSize ? `${founder}, ` : founder));
  }

  return (
    <Modal
      title={`${serviceName} - ${name} Overview`}
      footer={null}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      onOk={() => setIsOpen(false)}
    >
      <ProfileOverview
        heading={name}
        imageUrl={imageLink}
        rightContent={[
          { label: 'main industry', value: industry },
          { label: 'startup stage', value: stage },
          { label: 'website', value: website },
          { label: 'founders', value: returnFoundersString() },
        ]}
        subheadingIsLocation
        subheading={`${city}, ${country}`}
        userType={userType}
      />
      <Overview title={`About ${name}`}>
        {about}
      </Overview>
      <RequestList startupName={name} requestsList={requests} showTitle />
    </Modal>
  );
}

export default StartupDetails;
