import produce from 'immer';
import { userDefaultState } from 'redux/utils/defaultStates/UserDefaults';

import { SetUsersPayload, UserActionType } from 'types/reduxTypes/ActionTypes/UserTypes';
import { UserStateType, TogglerUserProfileStateType } from 'types/reduxTypes/StateTypes';
import { types } from 'redux/actions/users';

const defaultState: UserStateType = userDefaultState;

const handleSetServiceProviders = (state: UserStateType, payload: SetUsersPayload) => {
  state.user = payload.users;
  return state;
}

const handleToggleUserProfile = (state: TogglerUserProfileStateType) => {
  state.isVisible = !state.isVisible;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: UserStateType = defaultState, action: UserActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_USERS: {
      return handleSetServiceProviders(state, <SetUsersPayload>payload);
    }
    case types.TOGGLE_USER_PROFILE: {
      return handleToggleUserProfile(state);
    }
    case types.RESET_USERS: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
