import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import {
  selectRequests,
} from 'redux/selectors/request';
import { selectUserType } from 'redux/selectors/auth';
import { selectArticles } from 'redux/selectors/articles';
import { getArticles } from 'redux/actions/articles';

const mapStateToProps = (state: StateType) => ({
  articles: selectArticles(state),
  userRequests: selectRequests(state),
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getArticles,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
