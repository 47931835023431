import { identity } from 'utils';
import { queryBuilder } from '../../http_client';
import {
  GetArticlesParams, ResponseArticlesTypePayload, ResponseArticleType, ArticleType,
} from '../types';

export const convertGetArticleListResponse = (payload?: ResponseArticlesTypePayload[]): ResponseArticleType[] => {
  if (identity.isFullArray(payload)) {
    return payload!.map((item) => item);
  }
  return [];
}

export const convertGetArticleResponse = (payload?: ResponseArticleType):
ArticleType | undefined => {
  if (identity.isObjWithChildren(payload)) {
    return ({
      id: payload?.id!,
      title: payload?.title!,
      subTitle: payload?.subTitle!,
      readTime: payload?.readTime!,
      banner:  payload?.banner!,
      content: payload?.content!,
    })
  }
};

export const requestsQuery = (data?: GetArticlesParams) => {
  if (!identity.isObjWithChildren(data)) {
    return {};
  }

  const {
    limit,
    order,
    offset,
    from,
    to,
  } = data!;
  const queryTable = [];
  const params = queryBuilder()
    .orderBy(order)
    .limit(limit)
    .offset(offset)
    .preload()

  if (data?.isCount) {
    params
      .select(['status', { field: 'id', aggregation: 'count' }])
      .groupBy('status');
  }

  if (identity.isTruthyNumber(from) || identity.isTruthyNumber(to)) {
    let value = 'less';
    if (identity.isTruthyNumber(from) && identity.isTruthyNumber(to)) {
      value = 'range';
    } else if (identity.isTruthyNumber(from)) {
      value = 'greater';
    }
    queryTable.push({ tableName: 'articles', field: 'field', value: JSON.stringify({ value, from, to }) });
  }
  params.queryRelatedTable(queryTable);
  return params.toObject();
}

export const test = () => ''
