import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectAdgmLicenseByTechnicalLicenseId, selectTechnicalLicenseId } from 'redux/selectors/technicalLicenses';
import { getADGMLicenseByTechnicalLicenseId } from 'redux/actions/technicalLicense';
import { selectUserTechnicalLicenseId } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  adgm: selectAdgmLicenseByTechnicalLicenseId(state),
  userTechnicalLicenseId: selectUserTechnicalLicenseId(state),
  id: selectTechnicalLicenseId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ getADGMLicenseByTechnicalLicenseId }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
