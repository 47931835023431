import { ArticleStateType } from 'types/reduxTypes/StateTypes';

export const defaultArticlesState: ArticleStateType = {
  articles: [],
  articleDetails: {
    id: 0,
    title: '',
    subTitle: '',
    readTime: 0,
    banner: '',
    content: '',
  },
}

export default null;
