import { ServiceProviderType } from 'packages/service_repository';
import { ChangePasswordPayload } from 'types/auth/changePassword';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { StartupType } from 'types/startups';
import { AngelInvestorType } from 'types/angelInvestors';
import {
  LoginPayload,
  MyProfileType,
  SetAuthPayload,
  SetLoadingPayload,
} from 'types/auth';
import { ResetPasswordEmail, ResetPasswordPayload } from 'types/auth/resetPassword';
import { PartnerType } from 'packages/partner_repository';

export const types = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_STARTUP_STATUS: 'SET_STARTUP_STATUS',
  SET_ANGEL_INVESTOR_STATUS: 'SET_ANGEL_INVESTOR_STATUS',
  SET_TECHNICAL_STATUS: 'SET_TECHNICAL_STATUS',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  CHECK_IS_USER_AUTHENTICATED: 'CHECK_IS_USER_AUTHENTICATED',
  GET_RESET_PASSWORD_ACTION : 'GET_RESET_PASSWORD_ACTION',
  SET_AUTHENTICATION_CHECKED: 'SET_AUTHENTICATION_CHECKED',
  SET_IS_AUTH_LOADING: 'SET_IS_AUTH_LOADING',
  RESET_AUTH: 'RESET_AUTH',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  SET_STARTUP: 'SET_STARTUP',
  SET_ANGEL_INVESTOR: 'SET_ANGEL_INVESTOR',
  SET_TECHNICAL_LICENSE: 'SET_TECHNICAL_LICENSE',
  SET_SERVICE_PROVIDER: 'SET_SERVICE_PROVIDER',
  SET_PARTNER: 'SET_PARTNER',
  RESET_USER_NOTICE: 'RESET_USER_NOTICE',
  GET_MY_PROFILE_DATA: 'GET_MY_PROFILE_DATA',
  SET_MY_PROFILE_DATA: 'SET_MY_PROFILE_DATA',
  EDIT_MY_PROFILE_DATA: 'EDIT_MY_PROFILE_DATA',
}

export const loginSuccess = (payload: SetAuthPayload) => ({
  type: types.LOGIN_SUCCESS,
  payload,
});

export const checkIsUserAuthenticated = (payload: object = {}) => ({
  type: types.CHECK_IS_USER_AUTHENTICATED,
  payload,
});

export const getResetPassword = (payload: ResetPasswordEmail | ResetPasswordPayload) => ({
  type: types.GET_RESET_PASSWORD_ACTION,
  payload,
});

export const login = (payload: LoginPayload) => ({
  type: types.LOGIN,
  payload,
});

export const setIsLoading = (payload: SetLoadingPayload) => ({
  type: types.SET_IS_AUTH_LOADING,
  payload,
});

export const setAuthenticationChecked = (payload: object = {}) => ({
  type: types.SET_AUTHENTICATION_CHECKED,
  payload,
});

export const logout = (payload: object = {}) => ({
  type: types.LOGOUT,
  payload,
});

export const resetAuth = (payload: object = {}) => ({
  type: types.RESET_AUTH,
  payload,
});

export const changePassword = (payload: ChangePasswordPayload) => ({
  type: types.CHANGE_PASSWORD,
  payload,
});

export const setStartupStatus = (payload: DataPayload<string>) => ({
  type: types.SET_STARTUP_STATUS,
  payload,
});

export const setStartup = (payload: DataPayload<StartupType>) => ({
  type: types.SET_STARTUP,
  payload,
});

export const setAngelInvestorStatus = (payload: DataPayload<string>) => ({
  type: types.SET_ANGEL_INVESTOR_STATUS,
  payload,
});

export const setAngelInvestor = (payload: DataPayload<AngelInvestorType>) => ({
  type: types.SET_ANGEL_INVESTOR,
  payload,
});

export const setTechnicalLicenseStatus = (payload: DataPayload<string>) => ({
  type: types.SET_ANGEL_INVESTOR_STATUS,
  payload,
});

export const setTechnicalLicense = (payload: DataPayload<AngelInvestorType>) => ({
  type: types.SET_ANGEL_INVESTOR,
  payload,
});

export const setServiceProvider = (payload: DataPayload<ServiceProviderType>) => ({
  type: types.SET_SERVICE_PROVIDER,
  payload,
});

export const setPartner = (payload: DataPayload<PartnerType>) => ({
  type: types.SET_PARTNER,
  payload,
});

export const resetUserNotice = (payload: object = {}) => ({
  type: types.RESET_USER_NOTICE,
  payload,
});

export const getMyProfileData = (payload: object = {}) => ({
  type: types.GET_MY_PROFILE_DATA,
  payload,
});

export const setMyProfileData = (payload: DataPayload<MyProfileType>) => ({
  type: types.SET_MY_PROFILE_DATA,
  payload,
});

export const editMyProfileData = (payload: DataPayload<MyProfileType>) => ({
  type: types.EDIT_MY_PROFILE_DATA,
  payload,
});
