import React from 'react';
import { identity, DRAWERS } from 'utils';
import { getTechnicalLicenseFile } from 'components/EditTechnicalLicense/utils';
import { AboutTLHProps } from 'types/componentTypes/AboutProps';
import { RenderIf, Input } from 'components/components';
import { ViewUploadButton } from 'components/EditTechnicalLicense/components';

import './About.scss';

const AboutTLH = (props: AboutTLHProps) => {
  const {
    title,
    description,
    isTechnicalLicense,
    technicalLicenseDetails,
  } = props;

  const cleanDescriptionText = description.replace(/<\/?[^>]+(>|$)/g, '')

  const handleOpenDrawer = (drawerBody: string) => {
    props.actions.setIsDrawerOpen({ isDrawerOpen: true });
    props.actions.setDrawerBody({ drawerBody });
  }

  return (
    <div className="about">
      <div className="about__container">
        <RenderIf condition={identity.isTruthyString(title)}>
          <div className="about__header">
            <h3 className="about__title">{title}</h3>
          </div>
        </RenderIf>
        <div className="about__card">
          { cleanDescriptionText || 'No description or details specified' }
          { props.children }
        </div>
        <RenderIf condition={isTechnicalLicense}>
          <div className="about__details">
            <div className="details_row">
              <h4>Website:</h4>
              <p>{technicalLicenseDetails?.website}</p>
            </div>
            <RenderIf condition={identity.isTruthyString(technicalLicenseDetails?.acceptanceLetter)}>
              <div className="details_row">
                <h4>Acceptance Letter:</h4>
                <div className="acceptance-letter">
                  <Input.DraggerUpload
                    defaultFileList={identity.isTruthyString(technicalLicenseDetails?.acceptanceLetter)
                      ? [...getTechnicalLicenseFile(technicalLicenseDetails?.acceptanceLetter!)] : []}
                    previewType="image"
                    // beforeUpload={beforeAcceptanceLetterUpload}
                    accept=".pdf"
                    isRoundableButton
                    name={identity.isTruthyString(technicalLicenseDetails?.acceptanceLetter) ? 'acceptanceLetter' : ''}
                  />
                </div>
              </div>
            </RenderIf>
            <RenderIf condition={!identity.isZero(technicalLicenseDetails?.aDGMApprovedStatus)}>
              <div className="details_row">
                <h4>ADGM License:</h4>
                <div>
                  <ViewUploadButton
                    userType="Technical license"
                    name="ADGM License"
                    onClick={() => handleOpenDrawer(DRAWERS.tLHAdgmLicenseDetails)}
                    condition={technicalLicenseDetails?.aDGMApprovedStatus === 1
                      && identity.isTruthyString(technicalLicenseDetails?.currentADGMLicense?.aDGMLicenseUrl)}
                    status={technicalLicenseDetails?.aDGMApprovedStatus}
                  />
                </div>
              </div>
            </RenderIf>
            <RenderIf
              condition={false}
              // !identity.isZero(technicalLicenseDetails?.currentIncentiveAgreement?.licenseAgreementStatus)
            >
              <div className="details_row">
                <h4>Incentive Agreement:</h4>
                <div>
                  <ViewUploadButton
                    userType="Technical license"
                    name="Incentive Agreement"
                    onClick={() => handleOpenDrawer(DRAWERS.tLHIncentiveAgreementDetails)}
                    condition={technicalLicenseDetails?.currentIncentiveAgreement?.licenseAgreementStatus
                      && identity.isTruthyString(technicalLicenseDetails?.currentIncentiveAgreement?.file)}
                    status={technicalLicenseDetails?.currentIncentiveAgreement?.licenseAgreementStatus}
                  />
                </div>
              </div>
            </RenderIf>
          </div>
        </RenderIf>
      </div>
    </div>
  );
};

export default AboutTLH;
